<template>
  <v-card width="100%" style="border-radius: 12px; overflow: auto">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title
          >
          <!-- {{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }} -->
          <b>Vacation Approval</b>
          <v-btn
            style="float: right"
            @click="setFalse"
            elevation="0"
            density="comfortable"
            icon="mdi-close-circle"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <div class="mb-4">
        <span>#120034</span> <br />
        <div class="d-flex ga-2">
          <span class="font-weight-medium">Velayutham Sankaran</span>
          <v-chip label class="rounded-md h-25 bg-pink-darken-1 text-caption">
            Unplanned </v-chip
          ><br />
        </div>
        <span class="opacity-50 text-caption">Process Associate</span>
      </div>
        <v-card flat variant="tonal" class="pa-4 mb-4">
          <v-row no-gutters>
            <v-col cols="12" md="2">
              <span class="font-weight-medium">Sick Leave</span>
            </v-col>
            <v-col cols="12" md="4">
              <span class="font-weight-medium">09/01/2024 to 09/05/2024</span>
              <br />
              <span class="opacity-50 text-caption">4 Days 32 Hours</span>
            </v-col>
          </v-row>
        </v-card>
        <v-row no-gutters class="mb-4">
          <v-col cols="12" md="4">
            <span class="opacity-50">Blocked Calendar</span> <br />
            <span class="font-weight-medium">Yes</span>
          </v-col>
          <v-col cols="12" md="2">
            <span class="opacity-50">Reason</span> <br />
            <span class="font-weight-medium">Fever</span>
          </v-col>
          <v-col cols="12" md="2">
            <span class="opacity-50">Balance</span> <br />
            <span class="font-weight-medium">40 Hours</span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="opacity-50">Last availed on</span> <br />
            <span class="font-weight-medium">12-12-2023(4 Hours) Monday</span>
          </v-col>
        </v-row>
        <v-card flat variant="tonal" class="pa-6 mb-2">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <span class="font-weight-medium">I am not feeling well</span>
            </v-col>
          </v-row>
        </v-card>
        <span class="font-weight-bold text-subtitle-1">Approval Lines</span>
        <v-row no-gutters class="mt-6" v-for="i of 2" :key="i">
          <v-col cols="12" md="1" class="mt-4">
            <span>
              <CustomAvatar2
                :fName="'Bala'"
                :lName="'Ganesh'"
                :size="'medium'"
              ></CustomAvatar2>
            </span>
          </v-col>
          <v-col>
            <v-card flat variant="tonal" class="pa-6 mb-2">
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <span class="font-weight-medium">Velayutham Sankaran</span>
                  <br />
                  <span class="opacity-50 text-caption"
                    >20/08/2024 4 days old</span
                  >
                  <br />
                  <v-chip
                    label
                    class="rounded-md h-25 bg-pink-darken-1 text-caption"
                  >
                    Pending
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" align="end">
                  <v-row >
                    <v-col cols="12" class="pb-1">
                      <v-btn class="save float-right bg-indigo-darken-1" width="30%" height="30"
                        >Approve</v-btn
                      >
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-btn class="save float-right w-35 bg-indigo-darken-1" width="30%" height="30"
                        >Reject</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row no-gutters class="mt-3 pa-2">  
          <v-col cols="12" class="px-0 py-0" style="display: grid" v-if="formdata.id">
              <v-radio-group :disabled="showReadOnly" v-model="formdata.active" inline>
                <v-radio color="primary" label="Active" :value="1"></v-radio>
                <v-radio color="error" label="Inactive" :value="0"></v-radio>
              </v-radio-group>
            </v-col>
  
          <v-col cols="6">
             <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Start Date
            <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <TextFieldComponent
              density="compact"
              type="date"
              placeholder="startDate"
              :rules="startDateRule"
              v-model="formdata.start_date"
              :disabled="showReadOnly"
            />
          </v-col>
          <v-col cols="6" class="py-0 pl-3">
             <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">End Date
            <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <TextFieldComponent
              density="compact"
              type="date"
              placeholder="Start Date"
              :rules="endDateRule"
              v-model="formdata.end_date"
              :disabled="showReadOnly"
            />
          </v-col>  
          <v-col cols="12" >
             <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">remarks
            <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <v-textarea
                  label="Remarks"
                  row-height="25"
                  class="opacity-50"
                  rows="3"
                  variant="outlined"
                  single-line
                  v-model="formdata.remarks"
                ></v-textarea>
          </v-col>
            </v-row> -->
      </v-card-text>
      <!-- <v-card-action class="mr-3">
    
          <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
            <v-btn class="save" type="submit">Save</v-btn>
          </v-row>
        </v-card-action> -->
    </v-form>
  </v-card>
</template>

<script>
// import TextFieldComponent from "@/components/TextFieldComponent.vue";
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
import EnumService from "@/api/service/Enum/EnumService";
export default {
  props: ["id", "viewType"],
  components: {
    // TextFieldComponent,
    CustomAvatar2,
  },

  data() {
    return {
      showReadOnly: false,
      remarkRule: [(value) => !!value || "Domain is Required."],
      endDateRule: [(value) => !!value || "End Date is Required."],
      startDateRule: [(value) => !!value || "Start Date is Required."],
      dialog: false,
      type: null,
      formdata: {},
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formdata = {};
        this.getById(this.id);
      }
    },
  },

  created() {
    this.getEnumData();
    if (this.id) {
      this.getById(this.id);
    }
    // if (this.viewType) {
    //   this.showReadOnly = this.viewType == "edit" ? false : true;
    // }
  },

  methods: {
    checkDisplayValue() {
      this.formdata.data_value = this.formdata.display_value
        ? this.formdata.display_value
            .toLowerCase()
            .split(" ")
            .filter(Boolean)
            .join("")
        : "";
    },
    setFalse() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await EnumService.findOne(id);
      this.formdata = res[0];
    },
    async getEnumData() {
      let res = await EnumService.findAll();
      this.enumData = res.length > 0 ? res : [];
    },
    checkDomain() {
      this.formdata.domain = this.formdata.domain
        ? this.formdata.domain.toUpperCase()
        : "";
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log("Not valid");
      } else {
        if (this.formdata.id) {
          let output = await EnumService.update(this.formdata);
          console.log(output, "output");
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formdata = [];
        } else {
          let output = await EnumService.create(this.formdata);
          console.log(output, "gfdgg");
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formdata = [];
        }
      }
    },
  },
};
</script>

<style></style>
