<template>
    <v-card width="100%" style="border-radius: 12px; overflow: hidden">
      <v-form @submit.prevent="save" ref="form">
        <v-card-item>
          <v-card-title
          >{{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }}
          <b>Vacation Request</b>
          <v-btn style="float: right" @click="setFalse" elevation="0" density="comfortable" icon="mdi-close-circle"></v-btn>
      
    </v-card-title>
     </v-card-item>
  
      <v-card-text>
          <v-row no-gutters class="mt-3 pa-2">
          <!-- Input Field -->
  
          <v-col cols="12" class="px-0 py-0" style="display: grid" v-if="formdata.id">
              <v-radio-group :disabled="showReadOnly" v-model="formdata.active" inline>
                <v-radio color="primary" label="Active" :value="1"></v-radio>
                <v-radio color="error" label="Inactive" :value="0"></v-radio>
              </v-radio-group>
            </v-col>
  
          <v-col cols="6">
             <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Start Date
            <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <TextFieldComponent
              density="compact"
              type="date"
              placeholder="startDate"
              :rules="startDateRule"
              v-model="formdata.start_date"
              :disabled="showReadOnly"
            />
          </v-col>
          <v-col cols="6" class="pl-2">
             <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">End Date
            <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <TextFieldComponent
              density="compact"
              type="date"
              placeholder="End Date"
              :rules="endDateRule"
              v-model="formdata.end_date"
              :disabled="showReadOnly"
            />
          </v-col>
          <v-col cols="12">
             <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Vacation Type
            <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <AutoComplete
              density="compact"
              :items="VacationTypeServiceData"
              item-title="name"
              item-value="id"
              placeholder="Vacation Type Code"
              :rules="vacationTypeRule"
              v-model="formdata.vacation_type_id"
              :disabled="showReadOnly"
            />
          </v-col>
  
          <!-- Input Field -->
  
          <v-col cols="12" >
             <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">remarks
            <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700"></span></v-label>
            <v-textarea
                  label="Remarks"
                  row-height="25"
                  class="opacity-50"
                  rows="3"
                  variant="outlined"
                  single-line
                  v-model="formdata.remarks"
                ></v-textarea>
          </v-col>
            </v-row>
        </v-card-text>
        <v-card-action class="mr-3">
  
          <!-- Cancle And Save Button -->
  
          <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
            <v-btn class="save" type="submit">Save</v-btn>
          </v-row>
        </v-card-action>
      </v-form>
    </v-card>
  </template>
  
  <script>
  import TextFieldComponent from "@/components/TextFieldComponent.vue";
  import EnumService from "@/api/service/Enum/EnumService";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import VacationTypeService from "@/api/service/VacationType/VacationTypeService";
import VacationService from "@/api/service/Vacation/VacationService";
  export default {
    props: ["id", "viewType"],
    components: {
      TextFieldComponent,
      AutoComplete
    },
  
    data() {
      return {
        showReadOnly: false,
        remarkRule: [(value) => !!value || "Domain is Required."],
        endDateRule: [(value) => !!value || "End Date is Required."],
        vacationTypeRule: [(value) => !!value || "Vacation type is Required."],
        startDateRule: [(value) => !!value || "Start Date is Required."],
        dialog: false,
        type: null,
        VacationTypeServiceData:[],
        formdata: {},
      };
    },
  
    watch: {
      id() {
        if (this.id) {
          this.formdata = {};
          this.getById(this.id);
        }
      },
    },
  
    created() {
      this.getVacationTypeServiceData();
      if (this.id) {
        this.getById(this.id);
      }
      if (this.viewType) {
        this.showReadOnly = this.viewType == "edit" ? false : true;
      }
    },
  
    methods: {
      checkDisplayValue(){
        this.formdata.data_value = this.formdata.display_value?this.formdata.display_value.toLowerCase().split(' ').filter(Boolean).join(''):'';
      },
      setFalse() {
        this.$emit("closeDialog");
      },
      async getById(id) {
        let res = await EnumService.findOne(id);
        this.formdata = res[0];
      },
      async getVacationTypeServiceData() {
        let res = await VacationTypeService.findAll();
        this.VacationTypeServiceData = res.length > 0 ? res : [];
      },
      checkDomain(){
        this.formdata.domain= this.formdata.domain?this.formdata.domain.toUpperCase():''
      },
  
      async save() {
        let validation = await this.$refs.form.validate()
        if (!validation.valid) {
          console.log("Not valid")
        }
        else {
          if (this.formdata.id) {
            let output = await VacationService.update(this.formdata);
            console.log(output,'output')
            this.dialog = false;
            this.setFalse();
            this.$emit("reload", true);
            this.formdata = [];
           
          } else {
            let output = await VacationService.create(this.formdata);
            console.log(output, "gfdgg");
            this.dialog = false;
            this.setFalse();
            this.$emit("reload", true);
            this.formdata = [];
          }
        }
      },
    },
  };
  </script>
  
  <style>
  </style>