<template>
    <v-card flat class="crd-cls pa-6">
        <v-row>
            <v-col cols="6">
                <span class="yr-cls">2024</span>
            </v-col>            
            <v-col cols="6" class="d-flex align-center justify-end">
                <span class="gap show-cursor" style="color: blue;">Today</span>
                <span class="gap">|</span> 
                <span class="gap show-cursor">This Week</span>
                <span class="gap">|</span>
                <span class="gap show-cursor">This Month</span>
            </v-col>
        </v-row>
        <v-row class="mt-2">
            <v-col cols="3">
                <v-row>
                    <v-col cols="6" class="d-flex flex-column">
                        <span class="label">Vacation</span>
                        <span class="hrs-cls mt-2">40 Hours</span>
                    </v-col>
                    <v-col cols="6" class="d-flex flex-column">
                        <span class="label">PTO</span>
                        <span class="hrs-cls mt-2">40 Hours</span>
                    </v-col>
                    <v-col cols="6" class="d-flex flex-column">
                        <span class="label">Requests</span>
                        <span class="hrs-cls mt-2">40 Hours</span>
                    </v-col>
                    <v-col cols="6" class="d-flex flex-column">
                        <span class="label">Pipeline</span>
                        <span class="hrs-cls mt-2">40 Hours</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="1">
                <div class="vl"></div>
            </v-col>
            <v-col cols="2" class="d-flex flex-column justify-center">

                <v-row>      
                    <v-col cols="12" class="d-flex flex-column">
                        <span class="label">Planned Vacation</span>
                        <span class="hrs-cls mt-2">40 Hours / 5 People</span>
                    </v-col>                  
                    <v-col cols="12" class="d-flex flex-column">
                        <span class="label">Unplanned Vacation</span>
                        <span class="hrs-cls mt-2">10 Hours / 5 People</span>
                    </v-col>
                </v-row>
                
            </v-col>
            <v-col cols="3">
                
                <v-row>
                    <v-col cols="12">
                        <span>char</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" class="d-flex justify-center align-center">
                <v-row>
                    <v-col cols="12">
                        <span class="label">Blocked Calendar</span>
                    </v-col>
                    <v-col cols="12">
                        <span class="blocked-date">12-10-204 TO 14-102-24</span>
                    </v-col>
                    <v-col cols="12">
                        <span class="blocked-date">12-10-204 TO 14-102-24</span>
                    </v-col>
                    <v-col cols="12">
                        <span class="blocked-date">12-10-204 TO 14-102-24</span>
                    </v-col>
                </v-row>
            </v-col>

        </v-row>

    </v-card>

</template>
<script>
</script>
<style scoped>
.crd-cls{
    border: 1px solid #C6C6D1;
    background-color: #F3F2F5;
    border-radius: 12px;
}
.yr-cls{
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;

}
.label{
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
    color: #000000;
}
.hrs-cls{
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;

}
.gap {
    margin: 0 8px;
}

.gap:first-child {
    margin-left: 0;
}

.gap:last-child {
    margin-right: 0;
}
.vl {
  border-left: 1px solid #C6C6C7;
  height: 100%;
}
.blocked-date{
    font-size: 14px;
    font-weight: 500;
    background-color: #EFD3D3;
    width: fit-content;
    border-radius: 25px;
    padding: 8px 10px;
}


</style>