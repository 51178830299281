<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <AutoComplete
          v-model="candidateId"
          :items="candidateData"
          item-title="code"
          item-value="id"
          :placeholder="'Select Candidate Id'"
          @update:model-value="getCandidateData"
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-card flat style="background: #efeefd" class="pa-5">
          <v-row no-wrap>
            <v-col cols="12">
              <div style="width: 100%; color: #272727; font-weight: 800">
                {{ getName }}
                with {{ profileData && profileData.years_of_expeirence }} years
                Experience
              </div>
              <small style="color: black"
                >Notice Period
                {{
                  profileData && profileData.notice_period
                    ? getNoticePeriod(profileData.notice_period)
                    : ""
                }}</small
              >
            </v-col>
            <v-col cols="3">
              <div style="width: 100%; font-size: 12px; color: #727272">
                Email
              </div>
              <div style="width: 100%; color: #272727">
                {{ profileData && profileData.email }}
              </div>
            </v-col>
            <v-col cols="3">
              <div style="width: 100%; font-size: 12px; color: #727272">
                Mobile #
              </div>
              <div style="width: 100%; color: #272727">
                {{ profileData && profileData.phone_number }}
              </div>
            </v-col>
            <v-col cols="3">
              <div style="width: 100%; font-size: 12px; color: #727272">
                DOB
              </div>
              <div style="width: 100%; color: #272727">
                {{ profileData && profileData.dob }}
              </div>
            </v-col>
            <v-col cols="3">
              <div style="width: 100%; font-size: 12px; color: #727272">
                Assessment Date
              </div>
              <div style="width: 100%; color: #272727">
                {{ profileData && profileData.interview && profileData.interview.schedule_date && moment(profileData.interview.schedule_date).format('YYYY-MM-DD')}}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="8">
            <AnswerEvaluation v-if="candidateId" :candidateId="candidateId" />
          </v-col>
          <v-col cols="4">
            <Summary v-if="candidateId" :candidateId="candidateId"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Summary from "./Summary.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import AnswerEvaluation from "./AnswerEvaluation.vue";
import CandidateProfileService from "@/api/service/CandidateProfile/CandidateProfileService";
import moment from "moment";

export default {
  components: {
    AutoComplete,
    AnswerEvaluation,
    Summary,
  },
  data() {
    return {
      candidateData: [],
      profileData: null,
      candidateId: null,
      days: [
        { id: 1, day: "Immediate joining" },
        { id: 2, day: "less than 15days" },
        { id: 3, day: "less than 30days" },
        { id: 4, day: "less than 45days" },
        { id: 5, day: "less than 60days" },
        { id: 6, day: "less than 90days" },
      ],
    };
  },
  computed: {
    getName() {
      return this.profileData
        ? `${this.profileData.first_name} ${this.profileData.last_name}`
        : "";
    },
  },
  async created() {
    await this.getSelectedCandidate();
  },
  methods: {
    moment,
    getNoticePeriod(id) {
      let noticePeriod = this.days.filter((val) => val.id == id);
      return noticePeriod[0].day;
    },
    async getSelectedCandidate() {
      let res = await CandidateProfileService.findSelectedCandidate();
      this.candidateData = res;
    },
    async getCandidateData(id) {
      let res = await CandidateProfileService.findOne({ id: id });
      this.profileData = res ;
    },
  },
};
</script>
