<template>
    <input v-show="false" ref="fileInput" type="file" accept=".csv" @change="handleFileUpload" />
</template>
<script>
export default{
    data(){
        return {
            type:null

        }
    },
    methods:{
    uploadTheFile(){
        this.$refs.fileInput.click();
    },  
        
    handleFileUpload(event) {
        const file = event.target.files[0]; 
        const reader = new FileReader();   
        reader.onload = (e) => {
        const text = e.target.result;     
        let data = this.parseCSV(text);
        this.$emit("upload-data",data)
        };
        reader.readAsText(file);
    },
    parseCSV(text) {
        const rows = text.split('\n').filter(val=> val!='');
        const headers = rows[0].split(',').map(header => header.trim());
        const dataArray = rows.slice(1).map(row => {
        const values = row.split(',').map(value => value.trim());
        const obj = {};
        headers.forEach((header, index) => {
        obj[header] = values[index]; 
        });
        return obj;
        });

        return dataArray;
    },
    }

}
</script>