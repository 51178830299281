import ApiReportService from '../src/api/service/apiServiceReport.js';
import lodash from 'lodash';
import moment from 'moment'


const querystring = require('querystring')
class commonService {
    constructor() {

    }
    getTables($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "common/tables"
            $options.method = "get"
            if ($data) {
                $options.url = "common/tables?" + querystring.stringify($data)
                console.log($options.url)
            }
            // console.log($options, 'table dataaaaaa')
            ApiReportService.request($options)
                .then(response => {
                    console.log('test'); console.log(response.data.data, 'table dataaaaaa')

                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    }
    
    getFilterData($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "/getFilterData"
            $options.method = "get"
            if ($data) {
                $options.url = "/getFilterData" + querystring.stringify($data)
                console.log($options.url)
            }
            // console.log($options, 'table dataaaaaa')
            ApiReportService.request($options)
                .then(response => {
                    console.log('test'); console.log(response.data.data, 'table dataaaaaa')

                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    }
    
    getReport($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "getReport";
            $options.method = "post"
            console.log($options)
            ApiReportService.request($options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                });
        });
    }
    getformteTheDate(data,type){
        const date = new Date(data);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        let hours = date.getUTCHours();
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'
        hours = String(hours).padStart(2, '0');
        if(!type || type=='date'){
            return `${month}/${day}/${year}`;
        }else{
            return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
        }
    }
    updateFilter($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "updateFilter";
            $options.method = "post"
            console.log($options)
            ApiReportService.request($options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                });
        });
    }
    
    getSelectedFilter($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "getSelectedFilter";
            $options.method = "post"
            console.log($options)
            ApiReportService.request($options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                });
        });
    }
    getTableData($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getTableData"
            $options.method = "get"
            if ($data) {
                $options.url = "getTableData?" + querystring.stringify($data)
                console.log($options.url)
            }
            console.log($options, 'table dataaaaaa')
            ApiReportService.request($options)
                .then(response => {
                    console.log('test'); console.log(response.data.data, 'table dataaaaaa6666')

                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    }
    async getSort(data, key, type) {
        try {
            let $data = lodash.orderBy(data, [key], [type]);
            return $data
        }
        catch (e) {
            console.log(e)
            return data
        }
    }
    getObjectData(data, key, value) {
        if (data.length > 0 && key) {
            let $data = data.filter(ele => {
                return ele[key] == value
            })
            return $data.length > 0 ? $data[0] : null
        }

    }
    getTitleCase(data) {
        return data.toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

    }
    getCurrentDateToUtc(date) {
        //   if(type){
        //     let $date = new Date(date);
        //     let utcTime = $date.getTime() - ($date.getTimezoneOffset() * 70000);
        //     let utcDate = new Date(utcTime);
        //     console.log(utcDate,"hhhhhhhhhhhhhhhh2222")
        //     let utcDateString = utcDate.toISOString();
        //     let formatDate =moment(utcDateString).format('YYYY-MM-DD HH:mm:ss')
        //     return formatDate; 


        //   }
        //   else{
        var someDate = new Date(date);
        var utcDate = someDate.toUTCString();
        let formatDate = moment(utcDate).format('YYYY-MM-DD HH:mm:ss')
        console.log(someDate, "hhhhhhhhhhhhh444", formatDate)
        return formatDate;
        //   }
    }
    changeTheDateToUtc(date, timezone) {
        let parsedDateTime = moment.tz(date, "YYYY-MM-DDTHH:mm:ss", timezone);
        let utcDateTime = parsedDateTime.utc().format();
        return utcDateTime
    }
    getMultipleChip(data, idData, $key) {
        let $chipData = []
        if (data && data.length > 0 && idData && idData.length) {
            for (let i = 0; i < idData.length; i++) {
                let index = data.findIndex(k => k[$key] == idData[i])
                if (index > -1) {
                    $chipData.push(data[index])
                }
            }
            return $chipData
        }
        else {
            return []
        }
    }
    getObjectSliceMethod(data, idData, $key) {
        let $data = data && data.length>0 ? JSON.parse(data)  : []
        if ($data && $data.length > 0 && idData && idData.length) {
            for (let i = 0; i < idData.length; i++) {
                let index = $data.findIndex(k => k[$key] == idData[i])
                if (index > -1) {
                    $data.splice(index, 1)
                }
            }
            return $data
        }
        else {
            return $data
        }
    }
    async setFilterData(value) {
        value=await this.getSort(value,'sequence','asc')
        console.log(value,"iiiiiiiiiiiyy")
        let $data = []
        for (let $i = 0; $i < value.length; $i++) {
            if (value && value.length > 0) {
                let opt = await this.getColumnData(value[$i])
                if (opt) {
                    $data.push(opt)
                }
            }
        }
        console.log($data, "uuuuuuuuuuuu1")
        return $data
    }
    async getColumnData(value) {
        if (value.field_type == 'input' || value.field_type == 'textfield' || value.field_type == 'textarea' ) {
            return {
                column: value.column_name,
                label: value.label,
                required: false,
                duplicate: true,
                fieldType: 'text',
                text: value.column_name,
                maxLength: 250,
                minLength: 3,
                cols: value.width
            }
        }
        if (value.field_type == 'date' || value.field_type == 'datepicker') {
            return {
                column: value.column_name,
                label: value.label,
                required: false,
                duplicate: true,
                fieldType: 'datepicker',
                text: value.column_name,
                maxLength: 250,
                minLength: 3,
                cols: value.width
            }
        }
        if (value.field_type == 'autocomplete') {
            return {
                column: value.column_name,
                label: value.label,
                required: false,
                duplicate: true,
                multiple:value && value.multi_select && value.multi_select==1?true:false,
                fieldType: 'selection',
                options:await this.getTableData({tableName:value.source}),
                text: value.text,
                value: value.value,
                maxLength: 250,
                minLength: 3,
                cols: value.width
            }
        }
    }
    smallDialog() {
        let size = {
            width: '45%',
            height: '90%'
        }
        return size;
    }
    mediumDialog() {
        let size = {
            width: '800',
            height: '90%'
        }
        return size;
    }
    largeDialog() {
        let size = {
            width: '1200',
            height: '90%'
        }
        return size;
    }
    xSmallAvatar() {
        let size = {
            size: 10,
            font: '5px'
        }
        return size;
    }
    coustomAvatar() {
        let size = {
            size: 15,
            font: '8px'
        }
        return size;
    }
    smallAvatar() {
        let size = {
            size: 20,
            font: '10px'
        }
        return size;
    }
    mediumAvatar() {
        let size = {
            size: 30,
            font: '15px'
        }
        return size;
    }
    largeAvatar() {
        let size = {
            size: 40,
            font: '16px'
        }
        return size;
    }

}
export default new commonService();