<template>
  <div class="ml-5">
    <v-container class="d-flex justify-center">
      <v-card
        :title="!reScheduleId ? 'Schedule Interview' : 'Interview Status'"
        max-width="800"
        :class="!reScheduleId ? 'overflow-auto overflow-x-hidden' : 'w-100'"
        :height="!reScheduleId ? '600' : '500'"
      >
        <template v-slot:prepend>
          <v-img
            :src="require('../../assets/icons8-schedule-100.png')"
            width="35px"
            height="35px"
          />
        </template>
        <template v-slot:append>
          <v-btn icon="mdi-close" variant="text" @click="closeCard"></v-btn>
        </template>
        <v-form @submit.prevent="save" ref="form">
          <v-card-text class="mt-4">
            <v-row>
              <v-col cols="6" class="py-0 my-0" v-if="!reScheduleId">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Interviewer
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                    >*</span
                  ></v-label
                >
                <AutoComplete
                  v-model="scheduleInterViewData.interviewer"
                  :items="lAndDuser"
                  item-value="id"
                  item-title="name"
                  :placeholder="'Select the job role'"
                  :rules="interviewerRule"
                />
              </v-col>
              <v-col cols="6" class="py-0 my-0" v-if="!reScheduleId">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Location
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <AutoComplete
                  v-model="scheduleInterViewData.location"
                  :items="locationData"
                  item-value="id"
                  item-title="name"
                  :placeholder="'Place you coming from'"
                  :rules="locationRule"
                  :disabled="showReadOnly"
                />
              </v-col>
              <v-col cols="6" class="py-0 my-0">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Schedule on
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <TextFieldComponent
                  type="date"
                  :placeholder="'Date'"
                  :showtime="false"
                  v-model="date"
                />
              </v-col>
              <v-col cols="6" class="py-0 my-0">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Time
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <TextFieldComponent
                  :placeholder="'Need to hire'"
                  :type="'time'"
                  v-model="time"
                />
              </v-col>
              <!-- <v-col cols="6" class="py-0 my-0">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Start Time
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <TextFieldComponent
                  type="datetime"
                  :placeholder="'Start Time'"
                  :showtime="false"
                  v-model="date"
                />
              </v-col>
              <v-col cols="6" class="py-0 my-0">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >End Time
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <TextFieldComponent
                  type="datetime"
                  :placeholder="'End Time'"
                  :showtime="false"
                  v-model="date"
                />
              </v-col> -->

              <v-col cols="12" class="py-0 my-0">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Choose Template
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <AutoComplete
                  v-model="scheduleInterViewData.choose_template"
                  :items="assesData"
                  item-value="id"
                  item-title="name"
                  @update:model-value="setDescription"
                  :placeholder="'Choose Template'"
                />
              </v-col>
              <v-col cols="12" class="py-0 my-0">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Description
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <TextFieldComponent
                  v-model="scheduleInterViewData.description"
                  :placeholder="'Description'"
                  disabled
                />
              </v-col>
              <v-col cols="6" class="py-0 my-0" v-if="!reScheduleId">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Duration (mins)
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                    >*</span
                  ></v-label
                >
                <TextFieldComponent
                  v-model="scheduleInterViewData.duration"
                  :placeholder="'Duration'"
                  :rules="durationRule"
                  type="number"
                  disabled
                />
              </v-col>
              <v-col cols="6" class="py-0 my-0">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Interview round [E]
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                    >*</span
                  ></v-label
                >
                <EnumAutoCompleteVue
                  class="dropdown"
                  density="compact"
                  v-model="scheduleInterViewData.round_name"
                  :selected="scheduleInterViewData.round_name"
                  :domain="'INTERVIEW_ROUND'"
                  placeholder="Select round"
                  @changeEnumData="changeRound($event)"
                  :rules="roundRule"
                  :showReadOnly="scheduleInterViewData.id ? true : false"
                />
              </v-col>
              <v-col cols="6" class="py-0 my-0" v-if="reScheduleId">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Stage
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <AutoComplete
                  :items="stage"
                  item-title="stage"
                  item-value="stage"
                  v-model="scheduleInterViewData.stage"
                  placeholder="Select stage"
                  :rules="stageRule"
                />
              </v-col>

              <v-col cols="12" class="py-0 my-0" v-if="!reScheduleId">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Remarks
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <v-textarea
                  label="Update other details"
                  row-height="25"
                  class="opacity-50"
                  rows="3"
                  variant="outlined"
                  single-line
                  v-model="scheduleInterViewData.remarks"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="4" class="py-0 my-0" v-if="!reScheduleId">
                <v-checkbox
                  color="primary"
                  v-model="checkbox"
                  label="Click here to send an mail"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                class="py-0 my-0"
                v-if="!reScheduleId && checkbox"
              >
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >CC :
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <v-textarea
                  label="Mail"
                  row-height="25"
                  class="opacity-50"
                  rows="3"
                  variant="outlined"
                  single-line
                  v-model="scheduleInterViewData.email_cc"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row justify="end" class="dialogBottom">
              <v-btn class="mr-2 cancel" @click="closeCard">Cancel</v-btn>
              <v-btn class="save" type="submit">Schedule</v-btn>
            </v-row>
            <!-- <v-row justify="end" class="dialogBottom" v-if="!reScheduleId">
              <v-btn class="mr-2 bg-red" @click="closeCard">Rejected</v-btn>
              <v-btn class="bg-green" type="submit">Selected</v-btn>
            </v-row> -->
          </v-card-actions>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import LocationService from "@/api/service/Location/LocationService";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import ScheduleInterviewService from "@/api/service/ScheduleInterview/ScheduleInterviewService";
import CandidateProfileService from "@/api/service/CandidateProfile/CandidateProfileService";
import AssesmentService from "@/api/service/Assesments/AssesmentsService1";
// import DatePicker from "@/components/ui/DatePicker.vue";
import UserService from "@/api/service/User/UserService";
import EnumAutoCompleteVue from "@/components/others/EnumAutoComplete.vue";
import moment from "moment";

export default {
  props: ["candidateId", "reScheduleId"],
  components: {
    TextFieldComponent,
    AutoComplete,
    //  DatePicker
    EnumAutoCompleteVue,
  },
  data() {
    return {
      scheduleInterViewData: {},
      checkbox: false,
      lAndDuser: [],
      locationData: [],
      locationIdData: null,
      checkBox: false,
      date: null,
      time: null,
      assesData: [],
      interviewerRule: [(value) => !!value || "Mention Interviewer"],
      durationRule: [(value) => !!value || "Duration is required"],
      roundRule: [(value) => !!value || "Round is required"],
      templateRule: [(value) => !!value || "Template is required"],
      stage: ["Selected", "Rejected", "Hold"],
    };
  },
  async created() {
    await this.getAssesData();
    await this.getLandUser();
    await this.getLocationData();
    if (this.reScheduleId) {
      await this.getRoundData(this.reScheduleId);
    }
    if (this.candidateId) {
      await this.getCandidateProfileData(this.candidateId);
    }
  },

  methods: {
    async getRoundData(id) {
      let res = await ScheduleInterviewService.findOne(id);
      this.scheduleInterViewData = JSON.parse(JSON.stringify(res[0]));
      this.date = moment(this.scheduleInterViewData.schedule_date).format(
        "YYYY-MM-DD"
      );
      this.time = moment(this.scheduleInterViewData.schedule_date).format(
        "HH:mm"
      );
      console.log(res, "hhhhhssss");
    },
    async getLandUser() {
      try {
        let res = await UserService.getLandDUser();
        this.lAndDuser = res && res.length > 0 ? res : [];
      } catch (e) {
        this.lAndDuser = [];
      }
    },
    setDescription(selectedId) {
      if (selectedId) {
        this.scheduleInterViewData.description = this.assesData.find((asDt) => asDt.id == selectedId).topic;
        this.scheduleInterViewData.duration = this.assesData.find((asDt) => asDt.id == selectedId).duration;
      }
    },
    changeRound($event) {
      this.scheduleInterViewData.round_name = $event;
    },
    async getAssesData() {
      let result = await AssesmentService.findAll({});
      this.assesData = result;
    },
    selectDate($event) {
      this.date = $event;
      // this.getScheduledData();
    },
    async getLocationData() {
      let res = await LocationService.findAll();
      this.locationData = JSON.parse(JSON.stringify(res));
    },
    closeCard() {
      this.$emit("closeSchedule", false);
      this.scheduleInterViewData.id = null;
    },
    async save() {
      const { valid } = await this.$refs.form.validate();
      this.scheduleInterViewData.schedule_date = moment(
        this.date + " " + this.time
      ).format("YYYY-MM-DD HH:mm");
      console.log(this.scheduleInterViewData.schedule_date, "jjjjj");
      this.scheduleInterViewData.email_to = this.checkbox
        ? this.scheduleInterViewData.email_to
        : "";
      console.log(this.scheduleInterViewData, "jjjjj");
      if (!valid) {
        console.log("Error");
      } else {
        if (this.scheduleInterViewData.id) {
          let output = await ScheduleInterviewService.update(
            this.scheduleInterViewData
          );
          console.log(output);
        } else {
          let output = await ScheduleInterviewService.create(
            this.scheduleInterViewData
          );
          console.log(output);
          this.updateStatus(
            this.scheduleInterViewData.candidate_id,
            "Scheduled on"
          );
        }
        this.closeCard();
        this.$emit("reload", true);
      }
    },
    async updateStatus(id, status) {
      let output = await CandidateProfileService.update({
        id: id,
        status_of_interview: status,
      });
      console.log(output);
    },
    // async getScheduledData() {
    //   let res = await ScheduleInterviewService.scheduledDate({
    //     schedule_date: moment(date).format(
    //       "YYYY-MM-DD"
    //     ),
    //   });
    //   this.date = moment(res[0].schedule_date).format(
    //     "YYYY-MM-DD"
    //   );
    //   this.scheduleInterViewData.email_cc = JSON.parse(res[0].email_cc);
    //   this.scheduleInterViewData = res[0];
    //   console.log(res, "hhhhh");
    // },
    async getCandidateProfileData(id) {
      let res = await CandidateProfileService.findOne({ id: id });
      this.scheduleInterViewData.email_to = res.email;
      this.scheduleInterViewData.candidate_id = res.id;
      this.scheduleInterViewData.location = res.location;
    },
  },
};
</script>

<style>
.textHead {
  font-size: 14px;
  font-weight: 550;
  color: #272727;
}
.title-heading {
  font-size: 18px;
  font-weight: 800px;
  padding-top: 15px;
  height: max-content;
  width: max-content;
}
.title-close {
  transition: transform 1s ease-in-out;
  cursor: pointer;
}
.title-close:hover {
  font-size: 1.5625rem;
  transform: rotate(360deg);
}
.textHead {
  margin-bottom: 5px;
  padding-top: 15px;
}
</style>
