<template>
  <v-card flat>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <span class="tab gap show-cursor">My Vacation Requests</span>
        <span class="tab2 gap show-cursor">Pending Requests</span>
        <i
          class="pi pi-plus-circle gap show-cursor add-btn"
          @click="addRequest"
        />
      </v-col>
    </v-row>
    <v-row class="ma-1">
      <v-col cols="6" v-for="item in data" :key="item">
        <v-card flat class="list-card">
          <v-row>
            <v-col cols="6" class="d-flex" style="gap: 10px">
              <span>
                <CustomAvatar2
                  :fName="'Bala'"
                  :lName="'Ganesh'"
                  :size="'medium'"
                ></CustomAvatar2>
              </span>
              <span>
                <span class="name-cls next-row"> Bala Ganesh </span>
                <span class="role-cls"> Process Lead </span>
              </span>
              <span>
                <i
                  class="pi pi-pencil gap2 show-cursor"
                  style="font-size: 12px"
                  @click="addRequest"
                />
                <i
                  class="pi pi-trash gap2 show-cursor"
                  style="font-size: 12px"
                />
                <img
                  src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/coolicon.svg"
                  class="gap2"
                  width="12px"
                />
              </span>
            </v-col>
            <v-col cols="6" class="d-flex flex-column align-end">
              <span class="next-row"> 12-10-2024 to 12-15-2024 </span>
              <span class="role-cls"> 4 days (32 Hours) </span>
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-col>
              <span>
                My personal Trip with family for 4 days. Hence requesting to
                grant leave on those days
              </span>
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-col cols="8" class="d-flex" style="gap: 10px">
              <span class="role-cls"> 08-10-2024 09:00 AM </span>
              <span class="role-cls"> 3 days ago </span>
            </v-col>
            <v-col
              cols="4"
              class="d-flex align-end justify-end"
              style="gap: 10px"
            >
              <span class="chip-cls show-cursor" @click="vacationApproval">
                1
              </span>
              <span class="chip-cls show-cursor" @click="vacationApproval">
                2
              </span>
              <span class="chip-cls show-cursor" @click="vacationApproval">
                3
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
  <v-dialog persistent v-model="dialog" width="700px">
    <CreateRequest
      @closeDialog="close()"
      @reload="getRequestData()"
      :id="editId"
      :viewType="viewType"
    />
  </v-dialog>
  <v-dialog persistent v-model="dialog1" width="700px">
    <VacationApproval
      @closeDialog="close()"
      @reload="getRequestData()"
      :viewType="viewType"
    />
  </v-dialog>
</template>
<script>
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
import CreateRequest from "./CreateRequest.vue";
import VacationApproval from "./VacationApproval.vue";

export default {
  components: {
    CustomAvatar2,
    CreateRequest,
    VacationApproval,
  },
  data() {
    return {
      data: [1, 2, 3, 4, 6, 3, 3, 3],
      dialog: false,
      dialog1: false,
      editId: null,
      viewType: false,
    };
  },
  methods: {
    getRequestData() {},
    addRequest() {
      this.dialog = true;
    },
    vacationApproval() {
      this.dialog1 = true;
    },
    close() {
      this.dialog = false;
      this.dialog1 = false;
    },
  },
};
</script>
<style scoped>
.tab {
  font-size: 16px;
  font-weight: 600;
}
.tab2 {
  font-size: 16px;
  font-weight: 400;
}
.gap {
  margin: 0 10px;
}

.gap2 {
  margin: 0 5px;
}
.add-btn {
  font-size: 20px;
  font-weight: bold;
}
.name-cls {
  font-size: 14px;
  font-weight: 500;
}
.role-cls {
  font-size: 14px;
  font-weight: 400;
  color: #a1a1a1;
}
.chip-cls {
  background-color: #7ef6b6;
  padding: 2px 10px;
}
.list-card {
  border-radius: 15px;
  background-color: #f4f4f4;
  padding: 25px;
}
.next-row {
  display: block;
  margin-bottom: 4px;
}
</style>
