<template>
  <v-card flat>
    <!-- <v-tabs v-model="tab" color="primary">
      <v-tab value="one" class="font-weight-medium text-subtitle-2"
        >Needs Review</v-tab
      >
      <v-tab value="two" class="font-weight-medium text-subtitle-2"
        >Review All</v-tab
      >
    </v-tabs> -->
    
    <v-tabs v-model="tab" color="primary">
        <v-tab value="one" class="font-weight-medium text-subtitle-2"> Needs Review </v-tab>

        <v-tab value="two" class="font-weight-medium text-subtitle-2"> Review All </v-tab>
      </v-tabs>
      <v-card-text class="pt-0">
        <v-window v-model="tab">
          <v-window-item value="one">
            <QuestionList  :QuestionnaireData="QuestionnaireData" />
          </v-window-item>

          <v-window-item value="two">
            <QuestionList2  :QuestionnaireData="QuestionnaireData" />
          </v-window-item>
        </v-window>
      </v-card-text>


    <!-- <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="one">
          <QuestionList type="freeform" :QuestionnaireData="QuestionnaireData" />
        </v-tabs-window-item>

        <v-tabs-window-item value="two">
          <QuestionList2  :QuestionnaireData="QuestionnaireData" />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text> -->
  </v-card>
</template>
<script>
// import TextFieldComponent from "@/components/TextFieldComponent.vue";
import QuestionList from "./QuestionList.vue";
import QuestionList2 from "./QuestionList2.vue";
import QuestionnaireService from "@/api/service/Questionnaire/QuestionnaireService";

export default {
  props: ["candidateId"],
  components: {
    QuestionList,
    QuestionList2
  },
  data() {
    return {
      tab: null,
      totalMarks: 0,
      evaluationMark: null,
      QuestionnaireData: [],
    };
  },
  computed:{
    refresh(){
    return this.$store.getters.getRefreshSummary
    }
  },
  created() {
    this.tab = 'one'
    if (this.candidateId) {
    this.getQuestionnaireData(this.candidateId);
    }
  },

  watch: {
    candidateId() {
    this.getQuestionnaireData(this.candidateId);
    },
    refresh(){
    this.getQuestionnaireData(this.candidateId)
    }
  },

  methods: {
    async getQuestionnaireData(id) {
    let res = await QuestionnaireService.findQuestions({ candidate_id: id });
    this.QuestionnaireData = res;
    },
  },
};
</script>
