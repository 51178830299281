<template>
  <v-form @submit.prevent="save" ref="form" style="overflow-y: auto;">
    <v-card width="100%" style="border-radius: 12px; overflow: auto; min-height: 50vh">
      <v-card-item>
        <v-card-title class="font-weight-black">
          {{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }}
          Members to Workspace
        </v-card-title>
        <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
      </v-card-item>
      <v-card-text>
        <v-row dense class="pa-0 pt-3 ma-0">
          <!-- <v-radio-group
            color="primary"
            :v-model="form.active"
            :disabled="isRadioDisabled"
          >
            <v-row no-gutters>
              <v-col cols="2" class="pa-0">
                <v-radio label="Active" :value="1"></v-radio>
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-radio label="Inactive" :value="0"></v-radio>
              </v-col>
            </v-row>
          </v-radio-group> -->

          <v-radio-group :disabled="isRadioDisabled" v-model="form.active" inline>
            <v-radio color="primary" label="Active" :value="1"></v-radio>
            <v-radio color="error" label="Inactive" :value="0"></v-radio>
          </v-radio-group>

          <v-col cols="12" class="pr-5 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">User
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete v-model="form.user_id" :items="userData" :rules="userRule" :disabled="showReadOnly"
              :placeholder="'User Name'" item-title="name" item-value="id" />
          </v-col>
          <v-col cols="12" class="pr-5 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Role
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete v-model="form.role_id" :disabled="showReadOnly" :rules="roleRule" :items="roleData"
              :placeholder="'Role'" item-title="name" item-value="id" />
          </v-col>
          <v-col cols="6">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Start Date
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <span class="p-fluid">
              <DatePicker :showReadOnly="showReadOnly" :data="form.start_date" :rules="startRule"
                :placeholder="'Start Date'" @selectDate="selectStartDate($event)" :disabled="showReadOnly" />
            </span>
          </v-col>
          <v-col cols="6" :style="{'display':!showEndDate?'none':''}">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">End Date
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <span class="p-fluid">
              <DatePicker :showReadOnly="showReadOnly" :minDate="form.start_date" :data="form.end_date" :rules="dateRule"
                :placeholder="'End Date'" @selectDate="selectEndDate($event)" :disabled="showReadOnly" />
            </span>
          </v-col>
          <v-col cols="12">
            <v-row>
          <v-col cols="6" style="margin-top: -24px;">
            <v-checkbox color="primary" v-model="form.all_account" @change="changeAllAccount($event)" label="All Account"></v-checkbox>

          </v-col>
          <v-col cols="6" style="margin-top: -24px;">
            <v-checkbox color="primary" v-model="form.all_project" @change="changeAllProject($event)" label="All Project"></v-checkbox>

          </v-col>
        </v-row>
           </v-col>


          <v-col cols="12" v-if="!form.all_account" class="dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Accounts
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>

            <AutoComplete :items="accountDetails" item-value="id" item-title="name" density="compact"
              placeholder="Select Accounts" :rules="AccountsRule" v-model="form.account_id"
              @update:modelValue="accountChanged" :disabled="showReadOnly" />
            <v-error v-if="accountError">Account is required</v-error>
            <v-chip class="selectedChip" closable label v-for="item in selectedAccounts" :key="item" :close="false"
              :disabled="showReadOnly" @click:close="cancelAccounts(item)">
              {{ item.name }}
            </v-chip>
          </v-col>

          <v-col cols="12" v-if="!form.all_project" class="pt-3 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Projects
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>

            <AutoComplete :items="projectDetails" item-value="id" item-title="name" density="compact"
              placeholder="Select Projects" :rules="ProjectsRule" v-model="form.project_id"
              @update:modelValue="projectChanged" :disabled="showReadOnly" />
            <v-error v-if="projectError">Project is required</v-error>

            <v-chip class="selectedChip" label closable v-for="item in selectedProjects" :key="item"
              :disabled="showReadOnly" @click:close="cancelProject(item)">
              {{ item.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action>
        <v-row justify="end" class=" mb-4 dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-card>
  </v-form>
</template>
<script>
//import WorkSpaceService from '@/api/service/WorkSpace/WorkSpaceService';
import DatePicker from "@/components/ui/DatePicker.vue";
import AutoComplete from "../../../../components/ui/AutoComplete.vue";
import WorkSpaceTeamService from "@/api/service/WorkSpaceTeam/WorkSpaceTeamService";
import UserService from "@/api/service/UserService";
import AccountService from "@/api/service/Account/AccountService";
import ProjectService from "@/api/service/Project/ProjectService";
import RoleService from "@/api/service/Role/RoleService";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import commonService from "@/common.service";

export default {
  components: {
    AutoComplete,
    DatePicker,
  },
  props: ["id", "workSpaceId", "viewType"],

  data() {
    return {
      form: {
        user_id: null,
        role_id: null,
        start_date: null,
        active: 1,
        end_date: null,
        account_id: null,
        project_id: null,
        all_project: true,
        all_account: true
      },
      members: [
        {
          user_id: null,
          role_id: null,
          active: null,
        },
      ],
      accountDetails: [],
      backupAccounts: [],
      projectDetails: [],
      backupProjects: [],
      projectError: false,
      accountError: false,
      selectedAccountId: [],
      selectedProjectsId: [],
      selectedProjects: [],
      teams: [],
      userData: [],
      roleData: [],
      addRow: 0,
      showReadOnly: false,
      showEndDate: false,
      showbtn: false,
      userRule: [(value) => !!value || "User is Required."],
      roleRule: [(value) => !!value || "Role is Required."],
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },

  computed: {
    isRadioDisabled() {
      return this.viewType !== "edit" ? true : false;
    },
  },

  async created() {
    if (this.id) {
      this.getById(this.id);

    }
    else {
      await this.getAccounts();
      await this.getProjects();
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
      this.showEndDate =
        this.viewType == "edit" ? true : this.viewType == "view" ? true : false;
    }
    this.getWorkSpace();
    this.getWorkSpaceTeam();
    this.getUser();
    this.getRole();
  },
  mounted() { },
  methods: {
    changeAllAccount(){
      if(!this.form.all_account)
      {
        this.selectedAccountId =[]
        this.setDatas()
      }
      else{
        this.selectedAccountId =this.backupAccounts.map(k=>k.id)
      }

    },
    changeAllProject(){
      if(!this.form.all_account)
      {
        this.selectedProjectsId =[]
        this.setData()
      }
      else{
        this.selectedProjectsId =this.backupProjects.map(k=>k.id)
      }

    },
    toggleActive() {
      this.form.active = !this.form.active;
    },
    activeValue() {
      if (this.form.active === true) {
        return "Active";
      } else {
        return "Inactive";
      }
    },
    async getById(id) {
      await this.getAccounts();
      await this.getProjects();
      let res = await WorkSpaceTeamService.findOneWorkSpace(id);
      this.form = res[0];

      // console.log(this.form, "balaganesh")
      this.selectedAccountId =
        this.form && this.form.account_id
          ? JSON.parse(this.form.account_id)
          : [];
      this.selectedProjectsId =
        this.form && this.form.account_id
          ? JSON.parse(this.form.project_id)
          : [];
      this.form.all_account = this.form && this.form.all_account == 1 ? true : false
      this.form.all_project = this.form && this.form.all_project == 1 ? true : false
      this.setData();
      this.setDatas();
    },
    selectStartDate($event) {
      this.form.start_date = $event;
      this.form.end_date = null;
    },
    selectEndDate($event) {
      this.form.end_date = $event;
    },
    getWorkSpace() {
      WorkSpaceService.findOne({ id: this.workSpaceId }).then(($options) => {
        this.members = $options;
      });
    },
    getWorkSpaceTeam() {
      WorkSpaceTeamService.findAll().then(($options) => {
        this.teams = $options;
      });
    },

    async getProjects() {
      await ProjectService.getWorkSpaceProject({
        work_space_id: this.workSpaceId,
      }).then(async ($options) => {
        this.projectDetails =
          $options.length > 0
            ? await commonService.getSort($options, "name", "asc")
            : [];
        this.backupProjects = JSON.parse(JSON.stringify(this.projectDetails));
        this.selectedProjectsId =this.backupProjects.map(k=>k.id)
      });
    },

    async getAccounts() {
      await AccountService.getAccountByIssue({
        work_space_id: this.workSpaceId,
      }).then(async ($options) => {
        this.accountDetails =
          $options.length > 0
            ? await commonService.getSort($options, "name", "asc")
            : [];
        this.backupAccounts = JSON.parse(JSON.stringify(this.accountDetails));
        this.selectedAccountId =this.backupAccounts.map(k=>k.id)
      });
    },

    cancelProject(data) {
      let index = this.selectedProjectsId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedProjectsId.splice(index, 1);
      }
      this.setData();
    },

    cancelAccounts(data) {
      let index = this.selectedAccountId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedAccountId.splice(index, 1);
      }
      this.setDatas();
    },
    async getUser() {
      let $response = await UserService.findAll();
      $response =
        $response.length > 0
          ? await commonService.getSort($response, "name", "asc")
          : [];
      for (let i = 0; i < $response.length; i++) {
        if ($response[i].active != 0) {
          this.userData.push($response[i]);
        }
      }
      // console.log("ressss", this.userData);
    },

    setDatas() {
      this.form.account_id = null;
      let chipRes = commonService.getMultipleChip(
        this.backupAccounts,
        this.selectedAccountId,
        "id"
      );
      this.accountDetails = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupAccounts),
        this.selectedAccountId,
        "id"
      );
      this.selectedAccounts = chipRes;
      this.accountDetails = [...this.accountDetails];
    },

    projectChanged($value) {
      this.projectError = false;
      this.selectedProjectsId.push($value);

      this.setData();
    },

    setData() {
      this.form.project_id = null;
      let chipRes = commonService.getMultipleChip(
        this.backupProjects,
        this.selectedProjectsId,
        "id"
      );

      this.projectDetails = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupProjects),
        this.selectedProjectsId,
        "id"
      );
      this.selectedProjects = chipRes;
      this.projectDetails = [...this.projectDetails];
    },

    accountChanged($value) {
      this.accountError = false;
      this.selectedAccountId.push($value);
      this.setDatas();
    },
    async getRole() {
      let $response = await RoleService.findAll();
      this.roleData =
        $response.length > 0
          ? await commonService.getSort($response, "name", "asc")
          : [];
    },
    async save() {
      this.accountError = false;
      this.projectError = false;
      this.dateError = this.form.start_date ? false : true;
      let validation = await this.$refs.form.validate();
      if (
        !validation.valid ||
        this.codeError ||
        (this.selectedProjectsId.length == 0&& !this.form.all_account) ||
        (this.selectedAccountId.length == 0 && !this.form.all_project)
      ) {
        // console.log(validation.valid);
        if (this.selectedProjectsId.length == 0) {
          this.projectError = true;
        }
        if (this.selectedAccountId.length == 0) {
          this.accountError = true;
        }
      } else {
        this.form.project_id = JSON.stringify(this.selectedProjectsId);
        this.form.account_id = JSON.stringify(this.selectedAccountId);


        this.form.all_account = this.form && this.form.all_account == 1 ? true : false
        this.form.all_project = this.form && this.form.all_project == 1 ? true : false
        if (this.form.id) {
          this.form.work_space_id = this.workSpaceId;
          this.form.deleted = 0;
          WorkSpaceTeamService.update(this.form).then(($options) => {
            this.$emit("member-added", $options.data.data.id);
            this.$emit("reload", true);
          });
        } else {
          // this.form.start_date = new Date();
          this.form.end_date = null;
          this.form.work_space_id = this.workSpaceId;
          this.form.active = 1;
          this.form.deleted = 0;
          WorkSpaceTeamService.create(this.form).then(($options) => {
            this.$emit("member-added", $options.data.data.id);
            this.$emit("reload", true);
          });
        }
      }
      // else{
      //     this.dateError = true

      // }
    },
    addMember() {
      this.members.push({
        user_id: null,
        role_id: null,
        active: null,
      });
    },
    close() {
      this.$emit("closeDialog");
    },
  },
};
</script>
<style scoped>
v-error {
    color: red;
    padding-left: -16px;
    padding-left: 2%;
    font-size: 12px;
    padding-left: unset;
    position: relative;
    /* margin-top: 8px; */
    top: 0px;
}
>>>.v-input__details {
  align-items: flex-end;
  display: none;
  font-size: 0.75rem;
  font-weight: 400;
  grid-area: messages;
  letter-spacing: 0.0333333333em;
  line-height: normal;
  min-height: 22px;
  padding-top: 6px;
  overflow: hidden;
  justify-content: space-between;
}
</style>