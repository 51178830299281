<template>
  <div class="pa-4 text-h6 justify-center">
    <v-card
      class="overflow-auto overflow-x-hidden"
      :title="
        viewType == 'edit'
          ? 'Edit Assessment'
          : viewType == 'view'
          ? 'View Assessment  '
          : 'Add Assessment '
      "
      style="border-radius: 12px"
      height="600"
    >
      <template v-slot:prepend>
        <v-img
          :src="require('../../assets/evaluation-test-svgrepo-com.svg')"
          width="30px"
          height="30px"
        />
      </template>
      <template v-slot:append>
        <v-btn icon="mdi-close" variant="text" @click="closeCard"></v-btn>
      </template>
      <v-form @submit.prevent="save" ref="form">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6" class="px-0 py-0" style="display: grid">
              <v-radio-group
                :disabled="showReadOnly"
                v-model="assesmentData.candidate_type"
                inline
              >
                <v-radio
                  color="primary"
                  label="Candidate"
                  :value="1"
                ></v-radio>
                <v-radio
                  color="primary"
                  label="Existing employee"
                  :value="0"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" class="px-0 py-0" style="display: grid">
              <v-radio-group
                :disabled="showReadOnly"
                v-model="assesmentData.active"
                inline
                v-if="assesmentData.id"
              >
                <v-radio color="primary" label="Active" :value="1"></v-radio>
                <v-radio color="error" label="Inactive" :value="0"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12" class="py-0 my-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Assessment Name
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  >*</span
                ></v-label
              >
              <TextFieldComponent
                v-model="assesmentData.name"
                :placeholder="'Enter the name'"
                :rules="nameRule"
                :disabled="showReadOnly"
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="6">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Total Questions
                    <span
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label
                  >
                  <TextFieldComponent
                    v-model="assesmentData.no_of_question"
                    :placeholder="'Number of question'"
                    type="number"
                    :rules="questionCount"
                    @keyup="updateQuestionSelection"
                    :disabled="showReadOnly"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Duration(mins)
                    <span
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label
                  >
                  <TextFieldComponent
                    v-model="assesmentData.duration"
                    :placeholder="'Exam duration'"
                    type="number"
                    :rules="durationRule"
                    :disabled="showReadOnly"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Negative Scoring
                    <span
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                    ></span
                  ></v-label>
                  <TextFieldComponent
                    v-model="assesmentData.negative_scoring"
                    :placeholder="'If you need click'"
                    :rules="negativeScoringRule"
                    disabled
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Category
                    <span
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label
                  >
                  <AutoComplete
                    :items="assessmentCategory"
                    item-value="id"
                    item-title="name"
                    density="compact"
                    placeholder="Select category"
                    v-model="assesmentData.category_id"
                    @update:modelValue="changeCategoryData"
                    :disabled="showReadOnly"
                  />
                  <v-error v-if="codeError">Category is required</v-error>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" class="py-0 my-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Description
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <v-textarea
                label="Enter the topic"
                class="text-Field"
                rows="5"
                variant="outlined"
                single-line
                v-model="assesmentData.topic"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" class="py-0 my-0">
              <v-chip
                class="selectedChip"
                closable
                label
                v-for="item in selectedChip"
                :key="item"
                :close="false"
                @click:close="cancelCategory(item)"
                :disabled="showReadOnly"
              >
                {{ item && item.name ? item.name : null }}
              </v-chip>
            </v-col>      
            <v-col cols="12" md="4" class="py-0 my-0">
              <div class="d-flex flex-wrap">
                <v-checkbox
                  v-model="assesmentData.stage"
                  color="primary"
                  label="Allow Skip"
                  value="allowSkip"
                  :disabled="showReadOnly"
                ></v-checkbox>
                <v-checkbox
                  v-model="assesmentData.stage"
                  color="primary"
                  label="Mark for Review"
                  value="markForReview"
                  :disabled="showReadOnly"
                ></v-checkbox>
              </div>
            </v-col>                  
            <v-col cols="12"  v-if="showError && showError.totalCount && showError.totalCount.show">
              <v-error>{{ showError.totalCount.message  }}</v-error>
            </v-col>
            <v-col cols="12" class="mt-n4 mb-2">
              <span class="text-subtitle-1 font-weight-medium">
                Question Selection
              </span>
            </v-col>
            <v-col
              cols="12"
              v-for="(select,index) of questionSelection"
              :key="select.level"
              class="py-0 my-0"
            >
              <v-row>
                <v-col>
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Level
                    <span
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                    ></span
                  ></v-label>
                  <TextFieldComponent
                    v-model="select.level"
                    :placeholder="'Name of the question'"
                    disabled
                  />
                </v-col>
                <v-col>
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >Percentage
                    <span
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                      >*</span
                    ></v-label
                  >
                  <TextFieldComponent
                    v-model="select.percentage"
                    :placeholder="'Percentage'"
                    type="number"
                    @keyup="updateQuestionSelection"
                    :rules="percentageRule"
                  />
                </v-col>
                <v-col>
                  <v-label
                    class="pb-1"
                    style="font-size: 14px; color: #272727; font-weight: 600"
                    >No. Of Questions
                    <span
                      style="
                        color: #cf0707;
                        padding-left: 2px;
                        font-weight: 700;
                      "
                    ></span
                  ></v-label>
                  <TextFieldComponent
                    v-model="select.no_of_question"
                    :placeholder="'Number of question'"
                    type="number"
                    disabled
                  />
                </v-col>
              </v-row>
              <v-row v-if="select.percentage && showError[index==0?'starter':index==1?'moderate':'advanced'] && showError[index==0?'starter':index==1?'moderate':'advanced'].show">
                <v-col cols="12">
                    <v-error>{{ showError[index==0?'starter':index==1?'moderate':'advanced'].message }}</v-error>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-error v-if="errorMessage">{{ errorMessage }}</v-error>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="closeCard">Cancel</v-btn>
            <v-btn class="save" type="submit">Save</v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
    <!-- <v-snackbar color="Warning" v-model="snackbar" :timeout="'3000'">
      {{ errorMessage }}
    </v-snackbar> -->
  </div>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import AssesmentService from "@/api/service/Assesments/AssesmentsService1";
import EnumService from "@/api/service/Enum/EnumService";
import AssessmentCategoryService from "@/api/service/AssessmentCategory/AssessmentCategoryService";
import QuestionnaireService from "@/api/service/Questionnaire/QuestionnaireService";
import commonService from "@/common.service";


export default {
  components: { TextFieldComponent, AutoComplete },
  props: ["id", "viewType"],
  data() {
    return {
      showError:{
        starter:{
          show:false,
          message:"% that you updated we don't have sufficient questions in the Starter level."
        },
        moderate:{
          show:false,
          message:"% that you updated we don't have sufficient questions in the Moderate level."
        },
        advanced:{
          show:false,
          message:"% that you updated we don't have sufficient questions in the Advanced level."
        },        
        totalCount:{
          show:false,
          message:"Insufficient questions in the selected category."
        },
      },
      assessmentCategory:[],
      assesmentData: { stage: [], negative_scoring: "No",candidate_type:1 },
      errorMessage: null,
      selectedChip: [],
      codeError: false,
      categoryCount: null,
      bckupCategoryData: [],
      selectedCategoryId:[],
      noOfQuetionData:null,
      questionSelection: [
        {
          level: null,
          percentage: null,
          no_of_question: null,
        },
        {
          level: null,
          percentage: null,
          no_of_question: null,
        },
        {
          level: null,
          percentage: null,
          no_of_question: null,
        },
      ],
      // snackbar: false,
      showReadOnly: false,
      negativeScoring: ["YES", "NO"],
      assessmentCategoryData: [],
      renderValue: false,
      questionCount: [(value) => !!value || "Number Of Question Required"],
      codeRule: [(value) => !!value || "Code is Required"],
      nameRule: [(value) => !!value || "Name is Required"],
      topicRule: [(value) => !!value || "Topic is Required"],
      durationRule: [(value) => !!value || "Duration is Required"],
      negativeScoringRule: [
        (value) => !!value || "Negative Scoring is Required",
      ],
      percentageRule: [(value) => !!value || "Percentage is Required"],
    };
  },
  async created() {
    await this.getAssessmentCategoryData();
    await this.getEnumData();
    if (this.id) {
      await this.getValue(this.id);
    }
    if (this.viewType) {
      this.showReadOnly =
        this.viewType == "edit" || this.viewType == "add" ? false : true;
    }
  },
  methods: {
    checkQuetionCount(){
      if(this.assesmentData.no_of_question && this.noOfQuetionData){
        this.showError.totalCount.show =this.assesmentData.no_of_question>this.noOfQuetionData.total?true:false
        this.showError.starter.show = this.questionSelection[0].no_of_question && this.questionSelection[0].no_of_question>this.noOfQuetionData.starter?true:false
        this.showError.moderate.show =this.questionSelection[1].no_of_question && this.questionSelection[1].no_of_question>this.noOfQuetionData.moderate?true:false
        this.showError.advanced.show = this.questionSelection[2].no_of_question && this.questionSelection[2].no_of_question>this.noOfQuetionData.advanced?true:false
        return this.showError.totalCount.show || this.showError.starter.show ||this.showError.moderate.show || this.showError.advanced.show ?  true:false
      }
    },
    async getQuetionCount(){
      if(this.selectedCategoryId && this.selectedCategoryId.length>0){
        try{
          let res = await QuestionnaireService.checkQuestionCount({category_id:JSON.stringify(this.selectedCategoryId)})
          this.noOfQuetionData = res && res.length>0?res[0]:null
          this.checkQuetionCount()
        }
        catch(e){
          console.log(e);
        }
      }

    },
    changeCategoryData($value) {
      this.selectedCategoryId.push($value);
      this.setDatas();
    },
    setDatas() {
      this.assesmentData.category_id = null;
      let chipRes = commonService.getMultipleChip(
        this.bckupCategoryData,
        this.selectedCategoryId,
        "id"
      );
      this.assessmentCategory = commonService.getObjectSliceMethod(
        JSON.stringify(this.bckupCategoryData),
        this.selectedCategoryId,
        "id"
      );
      this.selectedChip = chipRes;
      this.assessmentCategory = [...this.assessmentCategory];
      this.getQuetionCount()
    },
    cancelCategory(data) {
      let index = this.selectedCategoryId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedCategoryId.splice(index, 1);
      }
      this.setDatas();
    },
    async getCategoryCount() {
      let categoryID = this.selectedChip.map((val) =>
        val && val.id ? val.id : ""
      );
      if (categoryID.length > 0) {
        let res = await QuestionnaireService.findCount({
          category_id: JSON.stringify(categoryID),
        });
        this.categoryCount = res[0].category_count;
      } 
    },
    async handleCategorySelection(selectedId) {
      if (selectedId) {
        let selectedData = this.assessmentCategoryData.find(
          (ct) => ct.id == selectedId
        );
        if (selectedData) {
          this.selectedChip.push(selectedData);
          this.bckupCategoryData = this.bckupCategoryData.filter(
            (ct) => ct.id !== selectedId
          );
          this.sortBackupCategoryData();
          this.assesmentData.category_id = null;
        }
      }
      this.categoryError();
      this.getCategoryCount();
    },
    async getEnumData() {
      let res = await EnumService.findAllDomain({
        domain: "ASSESSMENT",
        active: 1,
      });
      let enumData = JSON.parse(JSON.stringify(res));
      this.questionSelection.forEach((qs, qsIndex) => {
        enumData.map((ed, edIndex) => {
          if (edIndex == qsIndex) {
            qs.level = ed.display_value;
          }
        });
      });
    },
    removeCategory(category) {
      this.selectedChip = this.selectedChip.filter(
        (cat) => cat.id !== category.id
      );
      this.bckupCategoryData.push(category);
      this.sortBackupCategoryData();
      this.getCategoryCount();
    },
    sortBackupCategoryData() {
      this.bckupCategoryData.sort((a, b) => {
        let x = a.name.toLowerCase();
        let y = b.name.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    },
    closeCard() {
      this.$emit("closeCard", false);
    },
    categoryError() {
      if (this.selectedChip <= 0) {
        this.codeError = true;
      } else {
        this.codeError = false;
      }
    },
    async save() {
      const { valid } = await this.$refs.form.validate();
      let totalQuestions = this.questionSelection.reduce(
        (acc, cur) => acc + parseFloat(cur.no_of_question),
        0
      );
      let totalPercentage = this.questionSelection.reduce(
        (acc, cur) => acc + parseFloat(cur.percentage),
        0
      );
      this.categoryError();

      if (valid && !this.checkQuetionCount()) {
        // if (
        //   this.categoryCount >= parseFloat(this.assesmentData.no_of_question)
        // ) {
          if (
            totalQuestions === parseFloat(this.assesmentData.no_of_question) &&
            totalPercentage == 100
          ) {
            this.assesmentData.stage = JSON.stringify(this.assesmentData.stage);
            this.assesmentData.question_selection = JSON.stringify(
              this.questionSelection
            );
            this.assesmentData.category_id = JSON.stringify(this.selectedCategoryId)
            if (this.assesmentData.id) {
              let output = await AssesmentService.update(this.assesmentData);
              console.log(this.assesmentData, "gggggg", output);
              this.closeCard();
              this.$emit("reload", true);
            } else {
              let output = await AssesmentService.create(this.assesmentData);
              console.log(this.assesmentData, "gggggg", output);
              this.$emit("reload", true);
              this.closeCard();
              this.assesmentData = {};
            }
          } else {
            // this.snackbar = true;
            this.errorMessage = "Question Count doesn't tally";
          }
        // } else {
        //   // this.snackbar = true;
        //   this.errorMessage = "In this category question doesn't have";
        // }
      }
    },
    async getValue(id) {
      let res = await AssesmentService.findOne({ id: id });
      res = JSON.parse(JSON.stringify(res));
      this.assesmentData = res[0];
      console.log(res, "gdhfdghf");
      this.assesmentData.stage = JSON.parse(this.assesmentData.stage);
      this.assesmentData.question_selection = JSON.parse(
        this.assesmentData.question_selection
      );
      this.questionSelection = this.assesmentData.question_selection;
      this.selectedCategoryId = this.assesmentData.category_id ? JSON.parse(this.assesmentData.category_id):[]
      this.setDatas()
      let categoryData = JSON.parse(this.assesmentData.category_id);
      this.categoryCount = categoryData;
      categoryData.forEach((ctId) => {
        if (ctId) {
          console.log(this.assessmentCategoryData, "gdhfdghf");
          let editData = this.assessmentCategoryData.find(
            (ct) => ct.id == ctId
          );
          this.selectedChip.push(editData);
          this.bckupCategoryData = this.bckupCategoryData.filter(
            (ct) => ct.id !== ctId
          );
          this.sortBackupCategoryData();
        }
        // this.assesmentData.category_id = null;
        this.getCategoryCount();
      });
      console.log(this.assesmentData, "asss");
    },
    async getAssessmentCategoryData() {
      let res = await AssessmentCategoryService.findAll();
      this.assessmentCategory =res && res.length>0? await commonService.getSort(res,'name','asc') :[]
      this.bckupCategoryData = JSON.parse(JSON.stringify(this.assessmentCategory))
    },

    updateQuestionSelection() {
      const totalQuestions = parseFloat(this.assesmentData.no_of_question) || 0;

      if (totalQuestions <= 0) {
        this.questionSelection.forEach((level) => {
          level.no_of_question = 0;
        });
        return;
      }

      let calculatedQuestions = [];

      this.questionSelection.forEach((level) => {
        let percentage = parseFloat(level.percentage) || 0;
        let numQuestions = Math.round((percentage / 100) * totalQuestions);
        calculatedQuestions.push(numQuestions);
      });

      let totalCalculatedQuestions = calculatedQuestions.reduce(
        (sum, num) => sum + num,
        0
      );
      let difference = totalQuestions - totalCalculatedQuestions;

      if (difference !== 0) {
        calculatedQuestions[0] += difference;
      }

      this.questionSelection.forEach((level, index) => {
        level.no_of_question = calculatedQuestions[index];
      });
      this.checkQuetionCount()
    },
  },
};
</script>
<style></style>
