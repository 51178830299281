<template>
  <div class="d-flex justify-center">
    <v-card
      class="overflow-auto overflow-x-hidden"
      title="Candidate Profile"
      max-width="1000"
      height="600"
      style="border-radius: 12px"
    >
      <template v-slot:prepend>
        <v-img
          :src="require('../../assets/icons8-candidate-64.png')"
          width="30px"
          height="30px"
        />
      </template>
      <template v-slot:append>
        <v-btn icon="mdi-close" variant="text" @click="closeCard"></v-btn>
      </template>
      <v-form @submit.prevent="save" ref="form">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" class="px-0 py-0" style="display: grid">
              <v-radio-group
                :disabled="showReadOnly"
                v-model="candidateProfileData.candidate_type"
                @update:modelValue="changeType"
                inline
              >
                <v-radio
                  color="primary"
                  label="Candidate"
                  :value="1"
                ></v-radio>
                <v-radio
                  color="primary"
                  label="Existing employee"
                  :value="0"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6" class="py-0 my-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Email
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label>
              <TextFieldComponent v-if="candidateProfileData.candidate_type == 1"
                v-model="candidateProfileData.email"
                :placeholder="'Email of the Person'"
                :rules="emailRule"
                @update:modelValue="getCandidateDetails('email')"
              />              
              <AutoComplete v-else
                :items="userData"
                item-value="email"
                item-title="email"
                density="compact"
                placeholder="Email"
                v-model="candidateProfileData.email"
                @update:modelValue="getExistingUserData"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="6" class="py-0 my-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Phone No.
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label>
              <TextFieldComponent
                v-model="candidateProfileData.phone_number"
                :placeholder="'Enter your mobile number'"
                type="number"
                :rules="numRule"
                @update:modelValue="getCandidateDetails('number')"
              />
            </v-col>
            <v-col cols="12" class="py-0 my-0" v-if="candidateProfileData.candidate_type == 1">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Resume Title
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label>
              <TextFieldComponent
                v-model="candidateProfileData.resume_title"
                :placeholder="'Enter your previous role'"
                :rules="resumeRule"
              />
            </v-col>
            <v-col cols="12" class="py-0 my-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Applying Role
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <AutoComplete
                :items="roleData"
                item-value="id"
                item-title="name"
                density="compact"
                placeholder="Role"
                :rules="reportingRule"
                v-model="candidateProfileData.role_id"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="4" class="py-0 my-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >First Name
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label>
              <TextFieldComponent
                v-model="candidateProfileData.first_name"
                :placeholder="'First name'"
                :rules="firstNameRule"
              />
            </v-col>
            <v-col cols="4" class="py-0 my-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Middle Name
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="candidateProfileData.middle_name"
                :placeholder="'second Name'"
              />
            </v-col>
            <v-col cols="4" class="py-0 my-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Last Name
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label>
              <TextFieldComponent
                v-model="candidateProfileData.last_name"
                :placeholder="'Last Name'"
                :rules="lastNameRule"
              />
            </v-col>
            <v-col cols="4" class="py-0 my-0" v-if="!candidateProfileData.id && candidateProfileData.candidate_type == 1">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Current CTC (lks)
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="candidateProfileData.currenct_ctc"
                :placeholder="'Current CTC'"
                type="number"
                :rules="ctcRule"
              />
            </v-col>
            <v-col cols="4" class="py-0 my-0" v-if="!candidateProfileData.id &&  candidateProfileData.candidate_type == 1">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Expected CTC (lks)
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="candidateProfileData.expected_ctc"
                :placeholder="'Expected CTC'"
                type="number"
              />
            </v-col>
            <v-col cols="4" class="py-0 my-0" v-if="candidateProfileData.candidate_type == 1">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Expeirence (month)
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="candidateProfileData.years_of_expeirence"
                :placeholder="'Expeirence'"
                type="number"
              />
            </v-col>
            <v-col cols="4" class="py-0 my-0"  v-if="candidateProfileData.candidate_type == 1">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Notice Period (days)
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <AutoComplete
                v-model="candidateProfileData.notice_period"
                :items="days"
                item-value="id"
                item-title="day"
                :placeholder="'Select your days'"
              />
            </v-col>
            <v-col cols="4" class="py-0 my-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >DOB
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                >*</span
              ></v-label>
              <TextFieldComponent
                type="date"
                :placeholder="'Date'"
                :showtime="false"
                v-model="candidateProfileData.dob"
                :rules="dobRule"
              />
            </v-col>
            <v-col cols="4" class="py-0 my-0">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Location
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <AutoComplete
                v-model="candidateProfileData.location"
                :items="locationData"
                item-value="id"
                item-title="name"
                :placeholder="'Place you coming from'"
              />
            </v-col>
            <v-col cols="12" class="py-0 my-0">
              <v-row>
                <v-col cols="12" md="4" v-if="candidateProfileData.candidate_type == 1">
                  <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Add Skills
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="candidateProfileData.add_skills"
                :placeholder="'Enter the Skills'"
                append-inner-icon="mdi-plus-circle"
                clear-icon="mdi-close-circle"
                @click:append-inner="chipText"
                @click:clear="candidateProfileData.add_skills=null" 
                clearable
                @change="chipText"
                class="input-with-icon"

              />
                </v-col>
                <v-col cols="8" v-if="skillData">
              <v-chip
                v-for="chipData in skillData"
                :key="chipData"
                label
                closable
                variant="flat"
                :close="false"
                class="bg-teal-lighten-1 text-body-2 font-weight-medium ms-2 mt-5"
                @click:close="chipClose(chipData)"
                >{{ chipData }}</v-chip
              >
            </v-col>              </v-row>
            </v-col>
            <v-col cols="12" class="py-0 my-0"  v-if="candidateProfileData.candidate_type == 1">
              <v-label
                class="pb-1"
                style="font-size: 14px; color: #272727; font-weight: 600"
                >Reference
                <span
                  style="color: #cf0707; padding-left: 2px; font-weight: 700"
                ></span
              ></v-label>
              <TextFieldComponent
                v-model="candidateProfileData.reference"
                :placeholder="'Update the name who refered you here'"
              />
            </v-col>
            <template v-if="appear">
              <v-col cols="6" class="py-0 my-0">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Appeared before
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <TextFieldComponent
                  v-model="candidateProfileData.appeared_before"
                  :placeholder="'Name of the question'"
                />
              </v-col>
              <v-col cols="6" class="py-0 my-0">
                <v-label
                  class="pb-1"
                  style="font-size: 14px; color: #272727; font-weight: 600"
                  >Status of Interview
                  <span
                    style="color: #cf0707; padding-left: 2px; font-weight: 700"
                  ></span
                ></v-label>
                <TextFieldComponent
                  v-model="candidateProfileData.status_of_interview"
                  :placeholder="'Name of the question'"
                />
              </v-col>
            </template>
            <v-col class="d-flex ga-4 py-0 my-0" v-if="candidateProfileData.candidate_type == 1">
              <span class="text-body-2 font-weight-medium opacity-50"
                >Resume Attachment
              </span>
              <uploadFiles
                @documentsData="documentsData($event)"
                keyType="IS"
              />
              <v-chip
                v-if="
                  candidateProfileData.attach_resume &&
                  candidateProfileData.attach_resume.att_name
                "
                closable
                @click:close="
                  candidateProfileData.attach_resume.att_name = null
                "
              >
                {{
                  candidateProfileData.attach_resume &&
                  candidateProfileData.attach_resume.att_name
                    ? candidateProfileData.attach_resume.att_name
                    : ""
                }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="closeCard">Cancel</v-btn>
            <v-btn class="save" type="submit">Save</v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import CandidateProfileService from "@/api/service/CandidateProfile/CandidateProfileService";
import LocationService from "@/api/service/Location/LocationService";
import uploadFiles from "@/components/uploadFiles.vue";
import documentService from "@/api/service/Document/DocumentService";
// import DatePicker from "@/components/ui/DatePicker.vue";
import moment from "moment";
import RoleService from "@/api/service/Role/RoleService";
import UserService from '@/api/service/UserService';

export default {
  props: ["candidateId"],
  components: { TextFieldComponent, AutoComplete, uploadFiles },
  data() {
    return {
      candidateProfileData: {},
      attachmentId: [],
      attachmentData: [],
      userData: [],
      chipDsply: false,
      roleData: [],
      appear: false,
      atchShw: false,
      skillData: [],
      locationData: [],
      resumeFile: null,
      resumeFileName: "",
      emailRule: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      resumeRule: [(value) => !!value || "Title is Required."],
      firstNameRule: [(value) => !!value || "First Name is Required."],
      lastNameRule: [(value) => !!value || "Last Name is Required."],
      numRule: [
        (value) =>
          (value && value.length <= 10 && value.length > 9) ||
          "Not allowed more than ten digits",
      ],
      dobRule: [(value) => !!value || "DOB is Required."],
      days: [
        { id: 1, day: "Immediate joining" },
        { id: 2, day: "< 15days" },
        { id: 3, day: "< 30days" },
        { id: 4, day: "< 45days" },
        { id: 5, day: "< 60days" },
        { id: 6, day: "< 90days" },
      ],
    };
  },
  async created() {
    this.candidateProfileData.candidate_type = 1
    await this.getLocationData();
    await this.getRoleData();
    if (this.candidateId) {
      await this.getCandidateProfileData(this.candidateId);
    } 
    this.getUserData()
  },
  methods: {   
    changeType(){      
        this.candidateProfileData.email = null
        this.candidateProfileData.phone_number = null
        this.candidateProfileData.role_id = null
        this.candidateProfileData.first_name = null
        this.candidateProfileData.last_name = null
        this.candidateProfileData.location = null
    },
    getExistingUserData(){
        let selectUserData = this.userData.filter(k=>k.email.toLowerCase() == this.candidateProfileData.email.toLowerCase())
        console.log(selectUserData,"hello users")
        if(selectUserData && selectUserData.length>0){
          this.candidateProfileData.phone_number = selectUserData[0].mobile?selectUserData[0].mobile:null
          this.candidateProfileData.role_id = selectUserData[0].role_id?selectUserData[0].role_id:null
          this.candidateProfileData.first_name = selectUserData[0].first_name?selectUserData[0].first_name:null
          this.candidateProfileData.last_name = selectUserData[0].last_name?selectUserData[0].last_name:null
          this.candidateProfileData.location = selectUserData[0].location_id?selectUserData[0].location_id:null
        }
    },      
    async getUserData() {
            let res = await UserService.findAll()
            this.userData = res
        },
    selectDate($event) {
      this.candidateProfileData.dob = $event;
    },
    async getRoleData() {
      let res = await RoleService.findAll();
      this.roleData = res;
    },
    async getCandidateDetails(type) {
      let $selectedData;
      if (type == "email") {
        let email = { email: this.candidateProfileData.email };
        $selectedData = await this.getFindAll(email);
      } else {
        let phoneNumber = {
          phone_number: this.candidateProfileData.phone_number,
        };
        $selectedData = await this.getFindAll(phoneNumber);
      }
      console.log($selectedData, "hhhhhh");
      if ($selectedData.length > 0) {
        this.appear = !this.appear;
        this.skillData = JSON.parse($selectedData[0].add_skills);
        this.chipDsply = true;
        $selectedData[0].add_skills = null;
        this.candidateProfileData = $selectedData[0];
        console.log(this.candidateProfileData, "hhh");
      }
    },
    async getFindAll(value) {
      let res = await CandidateProfileService.findAll(value);
      res = JSON.parse(JSON.stringify(res));
      return res;
    },
    async documentsData($event) {
      this.candidateProfileData.attach_resume = {
        att_id: $event.id,
        att_name: $event.doc_name,
        att_url: $event.url,
      };
      console.log(this.candidateProfileData.attach_resume, "eeeeee");
      await this.getAttachementData();
    },
    async getAttachementData() {
      if (this.attachmentId.length > 0) {
        try {
          let res = await documentService.getAllDocuments({
            doc_id: JSON.stringify(this.attachmentId),
          });
          this.attachmentData = res;
          this.atchShw = true;
          console.log(this.attachmentData, "hhhh");
          this.$forceUpdate();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getCandidateProfileData(id) {
      let res = await CandidateProfileService.findOne({ id: id });
      this.candidateProfileData = res;
      delete this.candidateProfileData.interview
      this.candidateProfileData.dob = moment(this.candidateProfileData.dob).format("YYYY-MM-DD");
      this.skillData = JSON.parse(this.candidateProfileData.add_skills);
      this.chipDsply = true;
      this.candidateProfileData.add_skills = null;
      this.candidateProfileData.attach_resume = JSON.parse(
        this.candidateProfileData.attach_resume
      );
    },
    chipText() {
      this.skillData.push(this.candidateProfileData.add_skills);
      if (this.skillData.length > 0) {
        this.chipDsply = true;
      }
      this.candidateProfileData.add_skills = null;
    },
    chipClose(item) {
      let index = this.skillData.findIndex((k) => k == item);
      if (index > -1) {
        this.skillData.splice(index, 1);
      }
    },

    updateFileName(event) {
      const file = event.target.files[0];
      if (file) {
        this.resumeFile = file;
        this.resumeFileName = file.name;
      } else {
        this.resumeFileName = "";
      }
    },
    closeCard() {
      this.$emit("closeCard", false);
    },
    async getLocationData() {
      let res = await LocationService.findAll();
      this.locationData = JSON.parse(JSON.stringify(res));
      console.log(res, "rrr....");
    },
    async save() {
      const  validate = await this.$refs.form.validate();
      if(!validate.valid){
        console.log("Error")
      }
      else {
        this.candidateProfileData.add_skills = JSON.stringify(this.skillData);
        this.candidateProfileData.attach_resume = JSON.stringify(
          this.candidateProfileData.attach_resume
        );
        this.candidateProfileData.appeared_before =
          this.candidateProfileData.appeared_before == null
            ? new Date()
            : this.candidateProfileData.appeared_before;
        this.candidateProfileData.status_of_interview =
          this.candidateProfileData.status_of_interview == null
            ? "Profile uploaded"
            : this.candidateProfileData.status_of_interview;
        if (this.candidateProfileData.id) {
          let output = await CandidateProfileService.update(
            this.candidateProfileData
          );
          console.log(output);                
          this.candidateProfileData={}
          this.closeCard();
          this.$emit("reload", true);
        } else {
          let output = await CandidateProfileService.create(
            this.candidateProfileData
          );
          console.log(output);          
          this.candidateProfileData={}
          this.closeCard();
          this.$emit("reload", true);
        }
      }
      
    },
  },
};
</script>
<style scoped>
::placeholder {
  opacity: 0.5;
}
</style>
