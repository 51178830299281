
const getStageData = () => {
        let stageData =[
            { code:'open',name:'Open'},
            { code:'pending',name:'Pending'},
            { code:'inprogress',name:'In Progress'},
            { code:'qapending',name:'QA Pending'},
            // { code:'qacompleted',name:'QA Completed'},
            { code:'closed',name:'Closed'},
            // { code:'itsupportrequired',name:'IT Support Required'},
            // { code:'completed',name:'Completed'},
            // { code:'hold',name:'Hold'},
            { code:'cancel',name:'Cancel'},
            // { code:'clarification',name:'Clarification'},
            // { code:'followup',name:'followups'},
            { code:'assigned',name:'Assigned'},
            // { code:'errorlog',name:'Error Log'}
        ]
        return stageData
    }


module.exports ={ getStageData }