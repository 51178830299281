<template>
    <v-card flat>
        <Header />
        <DetailBoard class="ma-4" />
        <List class="ma-4" />
    </v-card>

</template>
<script>
import Header from './Header.vue'
import DetailBoard from './DetailBoard.vue'
import List from './List.vue'
export default {
    components:{
        List,
        Header,
        DetailBoard
    }

}
</script>


<style scoped>
</style>