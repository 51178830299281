<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b>Blocked Calenders </b>
          <v-btn style="float: right" @click="setFalse" elevation="0" density="comfortable"
            icon="mdi-close-circle"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="4" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Code" :rules="codeRule" v-model="formdata.code"
              :disabled="showReadOnly" @input="checkCode"/>
          </v-col>
          <v-col cols="4" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">From Date
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Enter the Date" :rules="dateRule" :type="'date'"
              v-model="formdata.from_date" :disabled="showReadOnly" />
          </v-col>

          <v-col cols="4" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">To Date
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Enter the Date" :rules="dateRule" :type="'date'"
              v-model="formdata.to_date" :disabled="showReadOnly" />
          </v-col>

          <v-col cols="12" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Subject
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Enter the Subject" :rules="subRule"
              v-model="formdata.subject" :disabled="showReadOnly" />
          </v-col>

          <v-col cols="12" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Reason
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Update your reason" :rules="reasonRule"
              v-model="formdata.reason" :disabled="showReadOnly" />
          </v-col>

          <v-col cols="6" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Entities Text
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Enter the Text" :rules="entitiesRule"
              v-model="formdata.entities_text" :disabled="showReadOnly" />
          </v-col>

          <v-col cols="6" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Users
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :placeholder="'Select the Users'" :items="usersData" item-title="username"
              :disabled="showReadOnly" item-value="id" v-model="formdata.user_id" :rules="usersRule" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import BlockedCalendarService from "@/api/service/BlockedCalendar/BlockedCalendarService";
import AutoComplete from "@/components/ui/AutoComplete.vue";

import UserService from "@/api/service/UserService";
import moment from "moment";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete,
  },

  data() {
    return {
      showReadOnly: false,
      usersData: [],
      dialog: false,
      codeRule:[v=>!!v||'Code is required'],
      dateRule:[v=>!!v||'Date is required'],
      subRule:[v=>!!v||'Subject is required'],
      reasonRule:[v=>!!v||'Reason is required'],
      entitiesRule:[v=>!!v||'Entities is required'],
      usersRule:[v=>!!v||'Users is required'],
      formdata: {},
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formdata = {};
        this.getById(this.id);
      }
    },
  },

  async created() {
    await this.getUsersData();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },

  methods: {
    // checkDisplayValue(){
    //   this.formdata.data_value = this.formdata.display_value?this.formdata.display_value.toLowerCase().split(' ').filter(Boolean).join(''):'';
    // },
    moment,
    setFalse() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await BlockedCalendarService.findOne({id});
      this.formdata = res[0];
      this.formdata.from_date=moment(this.formdata.from_date).format('YYYY-MM-DD')
      this.formdata.to_date=moment(this.formdata.to_date).format('YYYY-MM-DD')
    },
    async getUsersData() {
      let res = await UserService.findAll();
      this.usersData = res.length > 0 ? res : [];
      console.log(this.usersData, "lllllllll");
    },
    // checkDomain(){
    //   this.formdata.domain= this.formdata.domain?this.formdata.domain.toUpperCase():''
    // },
    checkCode() {
      if (this.formdata && this.formdata.code && this.formdata.code.length > 0) {
        this.formdata.code = this.formdata.code.toUpperCase();
        BlockedCalendarService.findAll({ code: this.formdata.code }).then((res) => {
          let $exist = res.filter(key => key.code == this.formdata.code)
          this.codeExistFlag = $exist.length > 0 ? (this.formdata.id && $exist[0].id == this.formdata.id) ? false : true : false;
          if (this.codeExistFlag) {
            this.codeError = true
          }
          else {
            this.codeError = false
          }
        });
      }
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log("Not valid");
      } else {
        if (this.formdata.id) {
          let output = await BlockedCalendarService.update(this.formdata);
          console.log(output, "output");
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formdata = [];
        } else {
          let output = await BlockedCalendarService.create(this.formdata);
          console.log(output, "gfdgg");
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formdata = [];
        }
      }
    },
  },
};
</script>

<style></style>
