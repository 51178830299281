

<script>
import UserService from "@/api/service/UserService";
import TimerService from "@/api/service/TimeSheet/TimerService.js";
import moment from "moment";
import { uuid } from 'vue-uuid';
export default {
  name: "loginComponent",
  components: {},
  data() {
    return {};
  },
  async created() {
    await this.login();
  },
  methods: {
    moment,
    async getDeviceId() {
      let $deviceId = localStorage.getItem('deviceId')
      console.log($deviceId)
      if(!$deviceId) {
        $deviceId  = await uuid.v1()
        localStorage.setItem("deviceId",$deviceId)
      }
      return Promise.resolve($deviceId)
    },
    async login() {
      if (this.$route.query.token) {
        let $deviceId = await this.getDeviceId();
       // alert($deviceId)
        let userData = await UserService.authenticate(  
          {
            "token":this.$route.query.token,
            "deviceId":$deviceId,
            "deviceInfo":window.navigator.userAgent
          })
        if (
          userData &&
          userData.data && userData.data.desks &&
          userData.data.desks.length > 0 &&
          userData.data.desks[0].token
        ) {
          localStorage.setItem("TOKEN", userData.data.desks[0].token);
          localStorage.setItem("sessionId",userData.data.desks[0].sessionId)          
          localStorage.setItem(
            "user_details",
            JSON.stringify(userData.data.userData)
          );
          let $time = {};
          await TimerService.processTime($time, "login", true);
         
          /*
          let $sessions = await UserService.findSessions({
            user_id: userData.data.userData.id,end_date:'null'
          });
          let $oldDeviceId = localStorage.getItem("device_id")
          if(!$oldDeviceId){
            localStorage.setItem("device_id",uuid.v1())
            $oldDeviceId = localStorage.getItem("device_id")
          }
        
          if($sessions && $sessions.length>0 && $sessions[0].device_id!=$oldDeviceId){
            localStorage.setItem("USER_SESSIONS",JSON.stringify($sessions[0]))
            this.$store.commit("SET_EXIST", true);
            localStorage.setItem("SET_EXIST",true)
          }else if($sessions && $sessions.length>0){
            localStorage.setItem("USER_SESSIONS",JSON.stringify($sessions[0]))
            this.$store.commit("SET_EXIST", false);
            localStorage.setItem("SET_EXIST",false);
          }*/
         this.$router.push('/')
          window.location.replace('/')
          
        }
      } else {
        // `${process.env.LOGIN_URL}?redirectUrl=${process.env.REDIRECT_URL}&appId=${process.env.APP_ID}`
        console.log(`${process.env.VUE_APP_LOGIN_URL}?redirectUrl=${process.env.VUE_APP_REDIRECT_URL}&appId=${process.env.VUE_APP_APP_ID}`)
        window.location =`${process.env.VUE_APP_LOGIN_URL}?redirectUrl=${process.env.VUE_APP_REDIRECT_URL}&appId=${process.env.VUE_APP_APP_ID}`
        //   "https://synergy-login.axodesk.com" +
        //   "?redirectUrl=" +
        //   window.location.href+"&appId="+config.appId;
        // window.location ='http://localhost:8081'+'?redirectUrl='+window.location.href
      }
    },
  },
};
</script>




