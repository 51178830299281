<template>
    <v-row class="mt-2 ml-2">
        <v-col>
            <span class="gm">
                Good Morning
            </span>
            <br/>
            <span class="name">
                Bala Ganesh
            </span>
        </v-col>
        <v-col class="d-flex align-center justify-end">
            <i class="pi pi-cog show-cursor" style="margin-top: 5px; font-size: 25px; margin-right: 25px;"></i>
            <v-btn class="mr-2 db-btn">
                Dashboard
                <i class="pi pi-chevron-down" style="margin-left: 4px;"></i>
            </v-btn>
        </v-col>
    </v-row>

</template>
<script>
</script>
<style scoped>
.name{
    font-weight: 700;
    font-size: large;
}
.gm{
    font-weight: 400;

}
.db-btn{
    background-color: #1258ab;
    color: #fff
}
</style>