<template>
  <v-row class="ml-3">
    <v-col cols="2">
      <v-label
        class="pb-1"
        style="font-size: 15px; color: #cf0707; font-weight: 800"
        >Workspace</v-label
      >
      <AutoComplete
        multiple="true"
        :items="backupWorkspace"
        :placeholder="'Workspace'"
        :item-title="'name'"
        :item-value="'id'"
        v-model="formData.workSpace_id"
        @update:modelValue="changeWorkSpaceData"
      />
    </v-col>

    <v-col cols="2">
      <v-label
        class="pb-1"
        style="font-size: 15px; color: #cf0707; font-weight: 800"
        >Account</v-label
      >
      <AutoComplete
        multiple="true"
        :items="accountData"
        :placeholder="'Account'"
        :item-title="'name'"
        :item-value="'id'"
        v-model="formData.account_id"
        @update:modelValue="changeFormData"
      />
    </v-col>

    <v-col cols="2">
      <v-label
        class="pb-1"
        style="font-size: 15px; color: #cf0707; font-weight: 800"
        >Created
        <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
          >*</span
        ></v-label
      >
      <DatePicker
        @update:modelValue="getExceptionData"
        :data="formData.created"
        :selectionMode="'range'"
        :placeholder="'Date'"
        @selectDate="selectDate($event)"
      />
    </v-col>

    <v-col cols="2">
      <v-label
        class="pb-1"
        style="font-size: 15px; color: #cf0707; font-weight: 800"
        >Stage
        <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
          >*</span
        ></v-label
      >
      <AutoComplete
        multiple="true"
        :items="stageData"
        :placeholder="'stage'"
        :item-title="'name'"
        :disabled="showReadOnly"
        :item-value="'code'"
        v-model="formData.stage_id"
      />
    </v-col>
    <v-col cols="2">
      <v-label
        class="pb-1"
        style="font-size: 15px; color: #cf0707; font-weight: 800"
        >Report Name
        <span style="color: #cf0707; padding-left: 2px; font-weight: 700"
          >*</span
        ></v-label
      >
      <AutoComplete
        multiple="true"
        :items="backupReport"
        :placeholder="'Subcategory'"
        :item-title="'name'"
        :disabled="showReadOnly"
        :item-value="'code'"
        v-model="formData.report_code"
        @update:modelValue="changeReport"
      />
    </v-col>
  </v-row>
  <div class="table-container ml-6">
    <table>
      <thead>
        <tr>
          <th class="first-column">Account</th>
          <th class="first-column">Created</th>
          <th class="first-column">Stage</th>
          <th
            class="headerElem"
            v-for="value in subCategoryService"
            :key="value.id"
          >
            <!-- <div class="headerText1">
							<span>
								<i
									:class="`pi ${
                      sortId && sortId == value.id
                        ? 'pi-sort-numeric-up'
                        : 'pi-sort-numeric-down-alt'
                    } show-cursor rotate-90`"
									@click="getSortTheData(value.id)"
								></i>
							</span>
						</div> -->
            <div class="headerText">
              <span
                >{{ value && value.name ? getSortName(value.name) : "" }}
                <v-tooltip activator="parent"> {{ value.name }} </v-tooltip>
              </span>
            </div>
          </th>
          <th class="headerElem total-column total-header-cell">
            <div class="headerText total-header"><span>Total</span></div>
          </th>
        </tr>
      </thead>
      <tbody class="scrollable-body">
        <tr v-for="account in accountData" :key="account">
          <td>
            <span style="font-weight: bold" class="headerElem account-name">
              {{ account.name }}</span
            >
          </td>
          <td>
            <div>
              <div
                class="workspace-cell"
                style="font-weight: bold; cursor: pointer"
              >
                {{
                  Array.isArray(formData.created) &&
                  formData.created[1] !== null
                    ? `${moment(formData.created[0]).format(
                        "DD-MM-YYYY"
                      )} - ${moment(formData.created[1]).format("DD-MM-YYYY")}`
                    : Array.isArray(formData.created) &&
                      formData.created[1] == null
                    ? moment(formData.created[0]).format("DD-MM-YYYY")
                    : moment(formData.created).format("DD-MM-YYYY")
                }}
              </div>
            </div>
          </td>
          <td>
            <div>
              <div
                class="workspace-cell"
                style="font-weight: bold; cursor: pointer"
              >
                <span>
                  <v-tooltip activator="parent">{{
                    formData.stage_id.join(", ")
                  }}</v-tooltip>
                  {{
                    formData && formData.stage_id
                      ? formData.stage_id.join(", ").length > 20
                        ? formData.stage_id.join(", ").slice(0, 20) + "..."
                        : formData.stage_id.join(", ")
                      : ""
                  }}
                </span>
              </div>
            </div>
          </td>
          <td v-for="subItem in subCategoryService" :key="subItem.id">
            <div>
              <div
                class="workspace-cell"
                style="font-weight: bold; cursor: pointer"
                @click="openIssues(account, subItem)"
              >
                {{ getTheCount(account, subItem) }}
              </div>
            </div>
          </td>
          <td class="total-column-cell" @click="openIssues(account, null)">
            <span style="font-weight: bold; cursor: pointer">
              {{ account ? getAccountTotal(account) : 0 }}
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="total-row">
          <td class="total-column-cell">Total</td>
          <td class="total-column-cell"></td>
          <td class="total-column-cell"></td>
          <td
            class="total-column-cell"
            v-for="item in subCategoryService"
            :key="item.id"
          >
            <span
              style="font-weight: bold; cursor: pointer"
              @click="openIssues(null, subItem)"
            >
              {{ getSubCategoryTotal(item) }}
            </span>
          </td>
          <td class="total-column-cell">
            <span
              style="font-weight: bold; cursor: pointer"
              @click="openIssues(null, null)"
            >
              {{ getTotal() }}
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <v-layout>
    <v-navigation-drawer
      :width="600"
      v-model="drawer"
      style="margin-top: 70px"
      location="right"
      permanent
    >
      <workSpaceView
        ref="workspaceView"
        @closedrawer="drawer = false"
      /> </v-navigation-drawer
  ></v-layout>
</template>

<script>
import DashboardService from "@/api/service/Dashboard/DashboardService.js";
import CategoryService from "@/api/service/Category/CategoryService";
import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
import AccountService from "@/api/service/Account/AccountService";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import DatePicker from "@/components/ui/DatePicker.vue";
import { getStageData } from "./ExceptionData";
import commonService from "@/common.service";
import moment from "moment";
import workSpaceView from "./workSpaceView.vue";
export default {
  components: {
    AutoComplete,
    DatePicker,
    workSpaceView,
  },
  data() {
    return {
      data: [],
      drawer: false,
      sortId: null,
      formData: {},
      categoryData: [],
      subCategoryService: [],
      accountData: [],
      backupAccount: [],
      backupWorkspace: [],
      backupReport: [],
    };
  },
  async created() {
    this.formData.stage_id = ["open"];
    this.formData.created = moment();
    this.getAccountData();
    this.getWorkspaceData();
    this.getcategoryData();
    this.stageData = getStageData();
    this.getExceptionData();
    this.getSubCategoryService();
  },
  methods: {
    moment,
    async openIssues(aData, subData) {
      this.drawer = true;
      let obj = {};
      obj.created = this.formData.created;
      obj.stage = this.formData.stage_id;
      obj.category_id = 88;
      if (aData && aData.id) {
        obj.account_id = aData && aData.id ? aData.id : null;
      }
      if (subData && subData.id) {
        obj.sub_category_id = subData && subData.id ? subData.id : null;
      }
      console.log(obj, "objobjobjobj");
      let res = await DashboardService.getExceptionIssues(obj);
      this.$refs.workspaceView.issueType = res && res.length > 0 ? res : [];
      this.$refs.workspaceView.createdData = this.formData.created;
      this.$refs.workspaceView.stage = this.formData.stage_id;
    },
    async getcategoryData() {
      let res = await CategoryService.findAll({ active: 1 });
      this.categoryData =
        res && res.length > 0
          ? await commonService.getSort(res, "name", "asc")
          : [];
    },
    async getSubCategoryService() {
      let res = await SubCategoryService.findAll({
        active: 1,
        category_id: 88,
      });
      this.subCategoryService =
        res && res.length > 0
          ? await commonService.getSort(res, "name", "asc")
          : [];
      this.backupReport = [...this.subCategoryService];
    },
    async getAccountData() {
      let res = await AccountService.findAll({ active: 1 });
      this.backupAccount =
        res && res.length > 0
          ? await commonService.getSort(res, "name", "asc")
          : [];
      this.backupAccount = [...this.backupAccount];
      this.accountData = JSON.parse(JSON.stringify(this.backupAccount));
    },
    async getWorkspaceData() {
      let res = await WorkSpaceService.findAll({ active: 1 });
      this.backupWorkspace =
        res && res.length > 0
          ? await commonService.getSort(res, "name", "asc")
          : [];
      this.backupWorkspace = [...this.backupWorkspace];
    },
    selectDate($event) {
      this.formData.created = $event;
      // alert("hiii")
      // alert($event)
      // this.formData.created = moment($event).format('DD-MM-YYYY')
      // this.formData = {...this.formData}
      // console.log(this.formData,"mmmmmmmm333")
      this.getExceptionData();
    },
    async getExceptionData() {
      let res = await DashboardService.getExceptionData(this.formData);
      this.data = res && res.data && res.data.data ? res.data.data : {};
      this.$forceUpdate();
    },
    getTheCount(aData, sData) {
      if (
        !this.data ||
        !this.data.data ||
        (this.data.data && this.data.data.length == 0)
      ) {
        return "-";
      }
      let opt = this.data.data.filter((k) => {
        return k.sub_category_id == sData.id && k.account_id == aData.id;
      });
      if (
        opt &&
        opt.length > 0 &&
        this.formData.stage_id &&
        this.formData.stage_id.length > 0
      ) {
        // let total = opt.reduce((acc, obj) =>
        // 	acc + (obj[this.formData.stage_id.forEach((item) => console.log(item,'itemitem'))] || 0), 0
        // );
        let total = 0;
        opt.forEach((obj) => {
          this.formData.stage_id.forEach((stage) => {
            total += obj[stage];
          });
        });
        return total > 0 ? total : 0;
      } else {
        return "-";
      }
    },
    getSubCategoryTotal(item) {
      if (
        !this.data ||
        !this.data.data ||
        (this.data.data && this.data.data.length == 0)
      ) {
        return "-";
      }
      let opt = this.data.data.filter((k) => {
        return k.sub_category_id == item.id;
      });
      if (
        opt &&
        opt.length > 0 &&
        this.formData.stage_id &&
        this.formData.stage_id.length > 0
      ) {
        // let total = opt.reduce((acc, obj) => acc + (obj[this.formData.stage_id] || 0), 0);
        let total = 0;
        opt.forEach((obj) => {
          this.formData.stage_id.forEach((stage) => {
            total += obj[stage];
          });
        });
        return total > 0 ? total : 0;
      } else {
        return "-";
      }
    },
    getAccountTotal(item) {
      if (
        !this.data ||
        !this.data.data ||
        (this.data.data && this.data.data.length == 0)
      ) {
        return "-";
      }
      let opt = this.data.data.filter((k) => {
        return k.account_id == item.id;
      });
      if (
        opt &&
        opt.length > 0 &&
        this.formData.stage_id &&
        this.formData.stage_id.length > 0
      ) {
        // let total = opt.reduce((acc, obj) => acc + (obj[this.formData.stage_id] || 0), 0);
        let total = 0;
        opt.forEach((obj) => {
          this.formData.stage_id.forEach((stage) => {
            total += obj[stage];
          });
        });
        return total > 0 ? total : 0;
      } else {
        return "-";
      }
    },
    getTotal() {
      if (
        this.data &&
        this.data.data &&
        this.data.data.length > 0 &&
        this.formData.stage_id &&
        this.formData.stage_id.length > 0
      ) {
        // let total = this.data.data.reduce((acc, obj) => acc + (obj[this.formData.stage_id] || 0), 0);
        let total = 0;
        this.data.data.forEach((obj) => {
          this.formData.stage_id.forEach((stage) => {
            total += obj[stage];
          });
        });
        return total > 0 ? total : 0;
      } else {
        return "-";
      }
    },
    changeFormData() {
      this.getExceptionData();
      if (this.formData && this.formData.account_id) {
        let filterData = [];
        for (let id of this.formData.account_id) {
          this.backupAccount.forEach((accDt) =>
            accDt.id == id ? filterData.push(accDt) : ""
          );
        }

        if (filterData.length > 0) {
          this.accountData = [...filterData];
        } else {
          this.accountData = this.backupAccount;
        }
      }
    },
    async changeWorkSpaceData() {
      let accId = [];
      this.backupWorkspace.forEach((wDt) => {
        this.formData.workSpace_id.forEach((wId) => {
          if (wDt.id == wId) {
            let aId = JSON.parse(wDt.account_id);
            accId = [...accId, ...aId];
          }
        });
      });
      let filterData = [];
      accId.forEach((id) => {
        this.backupAccount.forEach((accDt) =>
          accDt.id == id ? filterData.push(accDt) : ""
        );
      });
      if (filterData.length > 0) {
        this.accountData = [...filterData];
      } else {
        this.accountData = this.backupAccount;
      }
    },
    async changeReport() {
      let rId = [];
      this.backupReport.forEach((val) => {
        this.formData.report_code.forEach((code) => {
          val.code === code ? rId.push(val) : [];
        });
      });
      if (rId.length > 0) {
        this.subCategoryService = rId;
      } else {
        this.subCategoryService = this.backupReport;
      }
    },
    getSortTheData() {
      // this.sortId = aId;
      // localStorage.setItem("LB_SORT", aId);
      // this.ProductionData = lodash.orderBy(
      // this.ProductionData,
      // [
      // (item) => item.account_id !== aId,
      // (item) => item.account_id,
      // (item) =>
      // item[
      // !this.switchData || this.switchData == "issues"
      // ? "total_issues"
      // : "estimated"
      // ],
      // ],
      // ["asc", "asc", "asc"]
      // );
      // this.uniqueData();
    },
    getSortName(name) {
      if (name && name.length > 19) {
        let sliced = name.slice(0, 16);
        sliced = `${sliced}...`;
        return sliced;
      } else {
        return name;
      }
    },
  },
};
</script>

<style scoped>
/* Add a class to the "Total" column to keep it fixed */
.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

table {
  border-collapse: collapse;
  white-space: nowrap;
  width: fit-content;
}

.total-column {
  position: sticky;
  left: 0;
  background-color: #eef6fb;
  z-index: 3;
}

/* Set the header and "Total" column to be fixed */
thead {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
}

/* Add padding and adjust white-space for the account name */
td.account-name {
  padding: 8px;
  /* Adjust the padding as needed */
  white-space: nowrap;
  position: relative;
  z-index: 4;
  /* Set a higher z-index to bring it to the front */
}

.scrollable-body {
  overflow-x: auto;
  max-height: 300px;
  /* Set the desired max height for the scrollable area */
}

.table-container {
  overflow-x: auto;
  max-height: 600px;
}

/* Add a class to the "Total" column to keep it fixed */
td.total-column {
  position: sticky;
  left: 0;
  background-color: #eef6fb;
  z-index: 3;
}

/* Set the header and "Total" column to be fixed */
thead {
  position: sticky;
  top: 0;
  background: #fff;
}

/* Add padding and adjust white-space for the account name */
td.account-name {
  padding: 8px;
  /* Adjust the padding as needed */
  white-space: nowrap;
}

.scrollable-body {
  overflow: auto;
  max-height: 300px;
  /* Set the desired max height for the scrollable area */
  z-index: -1;
  /* Set the desired max height for the scrollable area */
}

.headerElem {
  white-space: nowrap;
  height: 15em;
}

.headerText {
  /* transform: rotate(-90deg); */
  position: relative;
  word-break: break-all;
  font-size: 16px;
  font-weight: 500;
}

td {
  padding: 6px;
  height: 42px;
  /* width: 50px; */
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

th:nth-child(1) {
  width: 150px !important;
  position: sticky;
  left: 0;
  background: #eef6fb;
  z-index: 3;
}

td:nth-child(1) {
  width: 150px !important;
  position: sticky;
  left: 0;
  text-align: left !important;
  font-size: 14px;
  font-weight: 700;
  background-color: #eef6fb;
}

th:last-child {
  /* width: 150px !important; */
  position: sticky;
  right: 0;
  z-index: 3;
}

td:last-child {
  /* width: 150px !important; */
  position: sticky;
  right: 0;
  font-size: 14px;
  font-weight: 700;
}

th:nth-child(odd) {
  /* width: 50px; */
}

td:nth-child(odd) {
  /* width: 50px; */
}

td:nth-child(even) {
  background: #e1eaef;
}

.rotate-90 {
  transform: rotate(90deg);
  margin: 50%;
}

/* Add these styles to your existing styles */

/* Set the first and last columns to stay fixed */
td.account-name,
td.total-column,
th.headerElem:nth-child(1),
th.headerElem.total-column {
  position: sticky;
  left: 0;
  background-color: #eef6fb;
  z-index: 3;
}

/* Set the first header cell to stay fixed */
th.headerElem:nth-child(1) {
  left: 0;
}

.total-header-cell {
  background-color: #eef6fb;
  /* Set the background color for the "Total" header cell */
}

/* Add a class for the "Total" value cells */
.total-column-cell {
  background-color: #eef6fb;
}

th.headerElem.total-column {
  position: sticky;
  left: 0;
  background-color: #eef6fb;
  z-index: 3;
  width: 20px !important;
}

/* Set the styles for the "Total" column header text */
.headerText.total-header {
  /* transform: rotate(-90deg); */
  position: relative;
  word-break: break-all;
  font-size: 16px;
  font-weight: 500;
}

/* Set the last header cell to stay fixed */
th.headerElem.total-column {
  position: sticky;
  left: auto;
  background-color: #eef6fb;
  z-index: 3;
  width: 20px !important;
}

/* Set the styles for the text within the header cells */
.headerText1 {
  transform: rotate(-90deg);
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  top: 14px;
  margin-left: 18px;
}

.headerText {
  transform: rotate(-90deg);
  position: relative;
  word-break: break-all;
  font-size: 16px;
  font-weight: 500;
  width: 50px;
  overflow: visible;
  position: relative;
  top: 60px;
  text-align: left;
}

/* Set the styles for the first column */
td.account-name {
  padding: 8px;
  /* Adjust the padding as needed */
  white-space: nowrap;
  position: relative;
  z-index: 4;
  /* Set a higher z-index to bring it to the front */
}

/* Adjust other styles as needed */

.total-row {
  position: sticky;
  bottom: 0;
  background-color: #eef6fb;
  z-index: 3;
}

.total-row td {
  font-weight: bold;
}

.headerElem:nth-child(even) {
  background: #e1eaef;
  width: 20px !important;
}

.first-column {
  width: auto;
  background-color: #eef6fb;
  /* Let the width be determined by content */
}

/* Add a general style for other columns */
.table-container th:not(.first-column),
.table-container td:not(.first-column) {
  width: 30px !important;
  overflow: hidden;
  /* You can customize other styles here */
}
</style>
